import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import Button from "../../atoms/Button";
import TabBar from "../../molecules/TabBar";
import FloatingButton from "../../atoms/FloatingButton";
import { COLOR_SYSTEM } from "../../design/design-system";
import JobPostCard from "../JobPostCard";
import TopAppBar from "../../molecules/TopAppBar/TopAppBar";
import { JobListState, JobListType } from "../../../store/jobpost/types";
import badgeIcon from "../../../assets/icon/nice_badge.png";
import { log, LogLevel } from "../../../utils/LogUtil";
import {
  AllInOnePartnerInfo,
  CompanyTypeName,
  UserInfo,
  UserWorkType,
  UserWorkTypeName,
} from "../../../models/Model.User";
import ReactHtmlParser from "react-html-parser";
import Text from "../../atoms/Text";
import Popup from "../../molecules/Popup";
import { withRouter, RouteComponentProps } from "react-router-dom";
import BottomSheet from "../../molecules/BottomSheet/BottomSheet";
import {
  displayTabbar,
  hideTabbar,
  isMobile,
} from "../../../utils/JobPostUtil";
import Badge from "../../atoms/Badge/Badge";
import { getGlobal, setGlobal } from "../../../utils/GlobalUtil";
import ABTestUtil, { ABTestFeature } from "../../../utils/ABTestUtil";
import ChannelTalkService from "../../../utils/ChannelTalkService";
import { throttle } from "lodash";
import Spinner from "../../atoms/Spinner";
import AnalyticsUtil from "../../../utils/AnalyticsUtil";

import { Flex, Header, Static } from "../../atoms/Layout";
import Scroll from "../../cell/Scroll";
import Icon from "../../atoms/Icon";
import Tag from "../../atoms/Tag";
import StringUtil from "../../../utils/StringUtil";
import { BoardContent } from "../../../models/Model.Board";
import ProfileImage from "../../ProfileImage";
import { getDateTimeStringShort } from "../../../utils/TimeUtil";
import "./PostListItem.scss";
import Avatar from "../../atoms/Avatar";
import { partnerThumbnail } from "../../../store/board/types";

export interface AllInOnePostListItemProps {
  content: BoardContent;
  partner: AllInOnePartnerInfo;
  showProfile?: boolean;
  onClick: () => void;
  hideCategory?: boolean;
}

const AllInOnePostListItem: React.FC<
  AllInOnePostListItemProps & RouteComponentProps
> = ({ content, partner, showProfile = false, onClick, hideCategory }) => {
  if (!content) return null;

  const imgRef = useRef();

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          console.log("intersecting");
          observer.disconnect();
          const lazyImage = entry.target as HTMLImageElement;
          lazyImage.src = lazyImage.dataset.src!;
          observer.disconnect();
        }
      });
    });

    observer.observe(imgRef.current);

    // Clean up
    return () => {
      observer.disconnect();
    };
  }, []);

  try {
    return (
      <div className="allinone-partner-post-container" onClick={onClick}>
        {showProfile && partner && (
          <div className="allinone-partner-post-profile-container">
            <Avatar
              size="Small"
              url={StringUtil.convertFilePath(partner.logoImageUrl)}
            />
            <Text textType="CaptionBold" color={COLOR_SYSTEM.get("Gray")[700]}>
              {partner.companyProfileName || partner.companyName}
            </Text>
            {!hideCategory && (
              <Tag size="Small" variant="Ghost" color="Blue">
                {CompanyTypeName[partner.companyType]}
              </Tag>
            )}
            <br />
          </div>
        )}
        <div className="allinone-partner-post-body-container">
          <div className="allinone-partner-post-body-information">
            <Text
              textType="Body2SemiBold"
              color={COLOR_SYSTEM.get("Gray")[800]}
              numberOfLines={2}
            >
              {content.isNice ? (
                <img
                  src={badgeIcon}
                  style={{
                    verticalAlign: "middle",
                    marginRight: "5px",
                    width: "17px",
                    height: "17px",
                  }}
                />
              ) : (
                ""
              )}
              {content.subject}
            </Text>
            <Flex
              alignItems="center"
              justifyContent="space-between"
              customStyle={{
                padding: "12px 0px 0px 0px",
              }}
            >
              <Flex alignItems="center" gap="12px">
                <Flex alignItems="center" gap="4px">
                  <Icon
                    name="Clock"
                    width={16}
                    height={16}
                    color={COLOR_SYSTEM.get("Gray")[200]}
                  />
                  <Text
                    textType="Caption"
                    color={COLOR_SYSTEM.get("Gray")[400]}
                  >
                    {getDateTimeStringShort(content.createdAt)}
                  </Text>
                </Flex>
                <Flex alignItems="center" gap="4px">
                  <Icon
                    name="Eye"
                    width={16}
                    height={16}
                    color={COLOR_SYSTEM.get("Gray")[200]}
                  />
                  <Text
                    textType="Caption"
                    color={COLOR_SYSTEM.get("Gray")[400]}
                  >
                    {content.viewCnt}
                  </Text>
                </Flex>
                <Flex alignItems="center" gap="4px">
                  <Icon
                    name="Thumbs Up"
                    width={16}
                    height={16}
                    color={COLOR_SYSTEM.get("Gray")[200]}
                  />
                  <Text
                    textType="Caption"
                    color={COLOR_SYSTEM.get("Gray")[400]}
                  >
                    {content.likeCntAll}
                  </Text>
                </Flex>
                <Flex alignItems="center" gap="4px">
                  <Icon
                    name="Comment Circle Dots"
                    width={16}
                    height={16}
                    color={COLOR_SYSTEM.get("Gray")[200]}
                  />
                  <Text
                    textType="Caption"
                    color={COLOR_SYSTEM.get("Gray")[400]}
                  >
                    {content.commentCnt}
                  </Text>
                </Flex>
              </Flex>
            </Flex>
          </div>
          {content.image ? (
            <img
              ref={imgRef}
              onError={() => partnerThumbnail("partners_default")}
              className="allinone-partner-post-body-image"
              data-src={StringUtil.convertFilePath(content.image)}
            />
          ) : (
            <img
              ref={imgRef}
              onError={() => partnerThumbnail("partners_default")}
              className="allinone-partner-post-body-image"
              data-src={
                partner &&
                partnerThumbnail(CompanyTypeName[partner.companyType])
              }
            />
          )}
        </div>
      </div>
    );
  } catch (error) {
    AnalyticsUtil.event(AnalyticsUtil.TYPE_ALL, "UI_Rendering_Error", "에러", {
      page: "AllInOnePostListItemTemplate",
      errorMessage: error,
    });
  }
};

export default withRouter(AllInOnePostListItem);
