import React, { Component } from "react";
import "./../../../Common.css";
import "./../../Admin.scss";
import { withRouter, RouteComponentProps } from "react-router";
import {
  IonButton,
  IonModal,
  IonSelect,
  IonSelectOption,
  IonList,
  IonItem,
  IonTextarea,
  IonInput,
  IonCheckbox,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonIcon,
  IonLabel,
} from "@ionic/react";
import { fetchAPI } from "../../../utils/APIUtil";
import { timeout, async } from "q";
import smileIcon from "./../assets/icon/smile.svg";
import { log, LogLevel } from "../../../utils/LogUtil";
import {
  AllInOnePartnerInfo,
  UserWorkTypeName,
  UserLevel,
  UserLevelName,
  RegisterRoute,
  REGISTER_ROUTE_NAME,
  UserPermanentType,
  UserWorkType,
  AllInOnePartnerLevel,
  CompanyType,
  CompanyTypeName,
  PARTNERS_TEMPLATE,
  UserInfo,
} from "../../../models/Model.User";
import { getGlobal, GlobalKey, setGlobal } from "../../../utils/GlobalUtil";
import { triggerAsyncId } from "async_hooks";
import { actions, RootState } from "../../../store";
import { connect } from "react-redux";
import { UIPopupType } from "../../../store/ui/types";
import {
  BoardAttribute,
  BoardContent,
  BoardContentQuiz,
} from "../../../models/Model.Board";
import {
  getDateStringFromToday,
  getDateTimeString,
} from "../../../utils/TimeUtil";
import StringUtil from "../../../utils/StringUtil";
import IconButton from "../../../component/atom/IconButton";
import { callPhone, sendSMSMessage } from "../../../utils/UrlSchemeUtil";
import { JobOfferName } from "../../../models/Model.JobPost";
import Button from "../../../components/atoms/Button";
import * as API from "../../../API.json";
import parse from "html-react-parser";
import ReactDOMServer from "react-dom/server";
import { loadImageBase64, loadImageUrlBase64 } from "../../../utils/ImageUtil";
import ImageViewNSelect, { ImageSize } from "../ImageViewNSelect";
import HtmlComposer from "../../../components/HtmlComposer";
import Textarea from "react-textarea-autosize";
import BoardSummary from "../../../components/templates/BoardSummary";
import { Flex, Static } from "../../../components/atoms/Layout";
import { MY_ADDRESS } from "../../../config.json";
import Text from "../../../components/atoms/Text";
import { COLOR_SYSTEM } from "../../../components/design/design-system";
import Separator from "../../../components/atoms/Separator";
import ToggleButton from "../../../components/atoms/ToggleButton/ToggleButton";
import Viewer from "../../../pages/Viewer";
import BoardQuizView from "../../../components/templates/BoardQuizView";
import Input from "../../../components/atoms/Input";

const AdminAPI = {
  PROMPT_SYSTEM_GET: {
    method: "GET",
    path: "/admin/quiz/prompt/system/",
    contentType: "application/json",
  },
  PROMPT_USER_GET: {
    method: "GET",
    path: "/admin/quiz/prompt/user/",
    contentType: "application/json",
  },
  QUIZ_DELETE: {
    method: "DELETE",
    path: "/admin/quiz/",
    contentType: "application/json",
  },
  QUIZ_PUT: {
    method: "PUT",
    path: "/admin/quiz",
    contentType: "application/json",
  },
};

type Props = typeof mapDispatchToProps &
  ReturnType<typeof mapStateToProps> & {
    onDone: (quiz: BoardContentQuiz) => void;
    onAdd?: (quiz: BoardContentQuiz) => void;
    onCancel: () => void;
    quiz: BoardContentQuiz;
    me: UserInfo;
  };

type State = AllInOnePartnerInfo & {
  mode?: "view" | "edit";
  systemPrompt?: string;
  foldSystemPrompt?: boolean;
  userPrompt?: string;
  foldUserPrompt?: boolean;
  quizJson?: string;
  quizJsonChanged?: boolean;
  quizJsonError?: boolean;
};

class QuizDetail extends Component<Props, State> {
  state: State = {
    mode: "view",
    foldSystemPrompt: true,
    systemPrompt: "",
    foldUserPrompt: true,
    userPrompt: "",
    quizJson: "",
    quizJsonChanged: false,
    quizJsonError: false,
  };

  constructor(props: Props) {
    super(props);
  }

  componentDidUpdate(prevProps, nextState) {
    if (
      this.props.quiz &&
      (!prevProps || !prevProps.quiz || this.props.quiz.id != prevProps.quiz.id)
    ) {
      this.setState({ userPrompt: "", foldUserPrompt: true });
    }
  }

  onModeChange = (mode: string) => {
    log(LogLevel.UI_ACTION, "QuizDetail onStartManualEdit", this.props.quiz);
    let newState: any = {
      mode,
    };
    switch (mode) {
      case "view":
        newState.quizJson = "";
        newState.quizJsonChanged = false;
        newState.quizJsonError = false;
        break;
      case "edit":
        let jsonQuiz = { ...this.props.quiz };
        delete jsonQuiz.createdAt;
        delete jsonQuiz.updatedAt;
        delete jsonQuiz.deletedAt;
        delete jsonQuiz.verified;
        delete jsonQuiz.verifiedAt;
        delete jsonQuiz.createdBy;
        delete jsonQuiz.verifiedBy;
        delete jsonQuiz.deletedBy;
        delete jsonQuiz.createdByUser;
        delete jsonQuiz.verifiedByUser;
        delete jsonQuiz.deletedByUser;
        newState.quizJson = JSON.stringify(jsonQuiz, null, 2);
        newState.quizJsonChanged = false;
        newState.quizJsonError = false;
        break;
    }
    this.setState(newState);
  };

  onShowSystemPrompt = (show: boolean) => () => {
    this.setState({ foldSystemPrompt: !show });
    if (show && !this.state.systemPrompt) {
      fetchAPI(
        AdminAPI.PROMPT_SYSTEM_GET,
        this.props.quiz &&
          this.props.quiz.version &&
          this.props.quiz.version.replace("M", ""),
        null,
        null,
        getGlobal(GlobalKey.TOKEN)
      )
        .then((result) => {
          if (result.data && result.data.system)
            this.setState({
              systemPrompt: result.data.system,
            });
        })
        .catch((e) => this.setState({ systemPrompt: "Error" }));
    }
  };

  onShowUserPrompt = (show: boolean) => () => {
    this.setState({ foldUserPrompt: !show });
    if (show && !this.state.userPrompt) {
      fetchAPI(
        AdminAPI.PROMPT_USER_GET,
        this.props.quiz &&
          this.props.quiz.contentId > 0 &&
          this.props.quiz.contentId,
        null,
        null,
        getGlobal(GlobalKey.TOKEN)
      )
        .then((result) => {
          if (result.data && result.data)
            this.setState({
              userPrompt: result.data,
            });
        })
        .catch((e) => this.setState({ userPrompt: "Error" }));
    }
  };

  onDelete = () => {
    this.props.confirmPopup.show({
      title: (
        <div className="common-container">
          <span className="common-bold">해당 퀴즈를 삭제하시겠습니까?</span>
        </div>
      ),
      body: <div className="common-container">원복시키기 어렵습니다.</div>,
      onDone: this.doDelete,
      doneText: "네",
    });
  };

  doDelete = async () => {
    this.props.progressPopup.show();
    await fetchAPI(
      AdminAPI.QUIZ_DELETE,
      this.props.quiz.id,
      null,
      null,
      getGlobal(GlobalKey.TOKEN)
    );
    this.props.progressPopup.hide();
    this.props.onDone(null);
  };

  onDoubleClick = (key: string) => () => {
    if (this.props.quiz[key] == this.props.me.id) return;
    if (!this.props.quiz[key.replace("By", "At")]) return;

    this.props.confirmPopup.show({
      title: (
        <div className="common-container">
          <span className="common-bold">
            {key}를 {this.props.me.name}으로 변경하시겠습니까?
          </span>
        </div>
      ),
      body: <div className="common-container">원복시키기 어렵습니다.</div>,
      onDone: this.doChangeByUser(key),
      doneText: "네",
    });
  };

  doChangeByUser = (key: string) => async () => {
    let quiz = { ...this.props.quiz };
    quiz[key] = this.props.me.id;
    await fetchAPI(
      AdminAPI.QUIZ_PUT,
      "",
      null,
      quiz,
      getGlobal(GlobalKey.TOKEN)
    );

    quiz[key + "User"] = this.props.me;
    this.props.onDone(quiz);
  };

  onVerify = async () => {
    this.props.progressPopup.show();
    let quiz = { ...this.props.quiz };
    if (quiz.verified) {
      quiz.verified = !quiz.verified;
      quiz.verifiedBy = 0;
      quiz.verifiedAt = null;
    } else {
      quiz.verified = !quiz.verified;
      quiz.verifiedBy = this.props.me.id;
      quiz.verifiedAt = getDateStringFromToday({ showTime: true });
    }
    await fetchAPI(
      AdminAPI.QUIZ_PUT,
      "",
      null,
      quiz,
      getGlobal(GlobalKey.TOKEN)
    );
    quiz["verifiedByUser"] = this.props.me;
    this.props.onDone(quiz);

    this.props.progressPopup.hide();
  };

  onSave = async () => {
    let quiz = null;

    try {
      quiz = { ...this.props.quiz, ...JSON.parse(this.state.quizJson) };
      log(LogLevel.UI_ACTION, "QuizDetail:onSave", quiz);
    } catch (e) {
      log(LogLevel.UI_ACTION, "QuizDetail:onSave Error", this.state.quizJson);
      this.setState({ quizJsonError: true });
      return;
    }
    this.props.progressPopup.show();
    await fetchAPI(
      AdminAPI.QUIZ_PUT,
      "",
      null,
      quiz,
      getGlobal(GlobalKey.TOKEN)
    );

    this.props.onDone(quiz);

    this.setState({
      quizJsonError: false,
      quizJsonChanged: false,
      quizJson: "",
      mode: "view",
    });
    this.props.progressPopup.hide();
  };

  render() {
    log(LogLevel.UI_ACTION, "QuizDetail render", this.props.quiz, this.state);
    if (!this.props.quiz) {
      return null;
    }

    return (
      <div className="admin-container">
        {this.props.onCancel && (
          <IonHeader class="common-header" no-border>
            <IonToolbar color="common-header-toolbar">
              <IonButtons slot="start" class="common-container">
                <IonButton
                  class="common-header-toolbar-button"
                  onClick={this.props.onCancel}
                >
                  <IonIcon name="arrow-back" mode="ios" />
                </IonButton>
                <IonLabel class="common-header-title common-flex-grow">
                  퀴즈 상세보기
                </IonLabel>
                <ToggleButton
                  onClick={() => this.onModeChange("view")}
                  size={"Small"}
                  type={"Text"}
                  variant={"Tinted"}
                  color={"Black"}
                  style={{ marginRight: "10px" }}
                  active={this.state.mode == "view"}
                  // disabled={this.state.mode == "view"}
                  // disabledColor={this.state.mode == "view"}
                >
                  미리보기
                </ToggleButton>
                <ToggleButton
                  onClick={() => this.onModeChange("edit")}
                  size={"Small"}
                  type={"Text"}
                  variant={"Tinted"}
                  color={"Black"}
                  style={{ marginRight: "10px" }}
                  active={this.state.mode == "edit"}
                  // disabled={this.state.mode == "edit"}
                  // disabledColor={this.state.mode == "edit"}
                >
                  수정하기
                </ToggleButton>
                <Button
                  onClick={this.onDelete}
                  size={"Small"}
                  type={"Text"}
                  variant={"Outlined"}
                  color={"Negative"}
                  style={{ marginRight: "10px" }}
                  // disabled={this.state.mode == "edit"}
                  // disabledColor={this.state.mode == "edit"}
                >
                  삭제하기
                </Button>
                <Button
                  onClick={this.onVerify}
                  size={"Small"}
                  type={"Text"}
                  variant={"Outlined"}
                  color={this.props.quiz.verified ? "Negative" : "Primary"}
                  style={{ marginRight: "10px" }}
                  // disabled={this.state.mode == "edit"}
                  // disabledColor={this.state.mode == "edit"}
                >
                  {this.props.quiz.verifiedAt ? "확정취소하기" : "확정하기"}
                </Button>
                {this.props.onAdd && (
                  <Button
                    onClick={(e) => {
                      e.stopPropagation();
                      if (this.props.quiz.usedCnt > 0) {
                        this.props.confirmPopup.show({
                          title: (
                            <div className="common-container">
                              <span className="common-bold common-color-caution">
                                경고! 출제된 적 있는 퀴즈입니다.
                              </span>
                            </div>
                          ),
                          body: (
                            <div className="common-container">
                              {this.props.quiz.usedCnt}회 출제되었습니다.
                              그럼에도 출제하시겠습니까?
                            </div>
                          ),
                          onDone: () => {
                            this.props.onAdd(this.props.quiz);
                          },
                          cancelText: "취소",
                          doneText: "네",
                        });
                      } else {
                        this.props.onAdd(this.props.quiz);
                      }
                    }}
                    size={"Small"}
                    type={"IconWithText"}
                    variant={"Tinted"}
                    color={"Primary"}
                    icon={"Plus"}
                    right
                    style={{ padding: "0px 8px" }}
                    // disabled={this.state.mode == "edit"}
                    // disabledColor={this.state.mode == "edit"}
                  >
                    DailyQuiz
                  </Button>
                )}
              </IonButtons>
            </IonToolbar>
          </IonHeader>
        )}

        <Static customStyle={{ padding: "0px 20px" }}>
          <Text textType="Body1SemiBold" color={COLOR_SYSTEM.get("Gray")[900]}>
            Id: {this.props.quiz.id} <br />
            ContentId: {this.props.quiz.contentId}
            <br />
            Version: {this.props.quiz.version}
            <br />
            CreatedAt:
            {getDateStringFromToday({ date: this.props.quiz.createdAt })}
            &nbsp;&nbsp;{" "}
            <span onDoubleClick={this.onDoubleClick("createdBy")}>
              {" "}
              by{" "}
              {this.props.quiz.createdByUser
                ? this.props.quiz.createdByUser.name
                : "미상"}
            </span>
            <br />
            DeletedAt:
            {this.props.quiz.deletedAt &&
              getDateStringFromToday({ date: this.props.quiz.deletedAt })}
            &nbsp;&nbsp;{" "}
            {this.props.quiz.deletedAt && (
              <span onDoubleClick={this.onDoubleClick("deletedBy")}>
                {" "}
                by{" "}
                {this.props.quiz.deletedByUser
                  ? this.props.quiz.deletedByUser.name
                  : "미상"}
              </span>
            )}
            <br />
            Verified:{" "}
            {this.props.quiz.verified ? (
              getDateStringFromToday({
                date: this.props.quiz.verifiedAt,
                showTime: true,
              })
            ) : (
              <Text textType="Body1Bold" color={COLOR_SYSTEM.get("Red")[400]}>
                X
              </Text>
            )}
            &nbsp;&nbsp;
            {!!this.props.quiz.verified && (
              <span onDoubleClick={this.onDoubleClick("verifiedBy")}>
                {" "}
                by{" "}
                {this.props.quiz.verifiedByUser
                  ? this.props.quiz.verifiedByUser.name
                  : "미상"}
              </span>
            )}
            <br />
            출제횟수:&nbsp;{this.props.quiz.usedCnt}
            <br />
            푼횟수(정답률):&nbsp;{this.props.quiz.successCnt}&nbsp;/&nbsp;
            {this.props.quiz.tryCnt}
            {this.props.quiz.tryCnt > 0 && (
              <span>
                (
                {Math.round(
                  (this.props.quiz.successCnt / this.props.quiz.tryCnt) * 100
                )}
                %)
              </span>
            )}
            <br />
            좋아요 / 싫어요 수:&nbsp;{this.props.quiz.likeCnt} /{" "}
            {this.props.quiz.dislikeCnt}
            <br />
          </Text>
        </Static>
        {this.renderDetail()}
        <Static
          customStyle={{
            padding: "0px 20px",
            marginTop: "20%",
            marginBottom: "40px",
            borderRadius: "20px",
            border: "1px solid  #e0e0e0",
          }}
        >
          {this.state.foldSystemPrompt ? (
            <Static
              customStyle={{ width: "100%" }}
              onClick={this.onShowSystemPrompt(true)}
            >
              <Text
                textType="Body1SemiBold"
                color={COLOR_SYSTEM.get("Gray")[600]}
              >
                시스템 프롬프트 보기 ⬇️
              </Text>
            </Static>
          ) : (
            <Flex direction="column" gap="20px">
              <Static
                customStyle={{ width: "100%" }}
                onClick={this.onShowSystemPrompt(false)}
              >
                <Text
                  textType="Body1SemiBold"
                  color={COLOR_SYSTEM.get("Gray")[600]}
                >
                  시스템 프롬프트 숨기기 ⬆️
                </Text>
              </Static>

              <Separator type={"Line"} />
              <Textarea
                minRows={5}
                value={this.state.systemPrompt}
                onClick={(e) => {
                  e.stopPropagation();
                }}
                style={{ padding: "20px" }}
              />
            </Flex>
          )}
        </Static>
        <Static
          customStyle={{
            padding: "0px 20px",
            marginTop: "20%",
            marginBottom: "40px",
            borderRadius: "20px",
            border: "1px solid  #e0e0e0",
          }}
        >
          {this.state.foldUserPrompt ? (
            <Static
              customStyle={{ width: "100%" }}
              onClick={this.onShowUserPrompt(true)}
            >
              <Text
                textType="Body1SemiBold"
                color={COLOR_SYSTEM.get("Gray")[600]}
              >
                유저 프롬프트 보기 ⬇️
              </Text>
            </Static>
          ) : (
            <Flex direction="column" gap="20px">
              <Static
                customStyle={{ width: "100%" }}
                onClick={this.onShowUserPrompt(false)}
              >
                <Text
                  textType="Body1SemiBold"
                  color={COLOR_SYSTEM.get("Gray")[600]}
                >
                  유저 프롬프트 숨기기 ⬆️
                </Text>
              </Static>

              <Separator type={"Line"} />
              <Textarea
                minRows={5}
                value={this.state.userPrompt}
                onClick={(e) => {
                  e.stopPropagation();
                }}
                style={{ padding: "20px" }}
              />
            </Flex>
          )}
        </Static>
      </div>
    );

    return null;
  }

  renderDetail = () => {
    if (!this.props.quiz) return null;

    switch (this.state.mode) {
      case "view":
        return (
          <Static>
            <BoardQuizView quiz={this.props.quiz} canReset={true} />
          </Static>
        );
        break;
      case "edit":
        return (
          <Flex direction="column" gap="8px" style={{ padding: "0px 20px" }}>
            <Button
              size={"Large"}
              type={"Text"}
              variant={"Contained"}
              color={"Primary"}
              disabled={!this.state.quizJsonChanged}
              disabledColor={!this.state.quizJsonChanged}
              onClick={this.onSave}
            >
              저장
            </Button>
            <Textarea
              minRows={5}
              value={this.state.quizJson}
              style={{ color: this.state.quizJsonError ? "red" : "black" }}
              onChange={(e) =>
                this.setState({
                  quizJson: e.target.value,
                  quizJsonChanged: true,
                })
              }
            />
          </Flex>
        );
        break;
    }
  };
}

const mapStateToProps = (state: RootState) => ({
  filePath: state.board.filePath,
  toastPopup: state.ui.popups[UIPopupType.TOAST_POPUP],
  confirmPopup: state.ui.popups[UIPopupType.CONFIRM_POPUP],
  progressPopup: state.ui.popups[UIPopupType.WAITING_POPUP],
});

const mapDispatchToProps = {
  //   logOutUser: () => actions.partner.logout(),
  //   refreshBoards: () => actions.board.refreshBoards(),
};
export default connect(mapStateToProps, mapDispatchToProps)(QuizDetail);
