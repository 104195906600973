import { UserInfo, UserWorkTypeName } from "../models/Model.User";
import { log, LogLevel } from "./LogUtil";

const windowAny: any = window;

class ChannelTalkService {
  static instance: ChannelTalkService = null;
  static on: boolean = false;
  static me: UserInfo = null;
  static isShown: boolean = false;
  constructor() {
    this.loadScript();
  }

  static getInstance = () => {
    if (ChannelTalkService.instance == null)
      ChannelTalkService.instance = new ChannelTalkService();
    return ChannelTalkService.instance;
  };

  loadScript() {
    if (windowAny.ChannelIO) {
      return (window.console.error || window.console.log || function () {})(
        "ChannelIO script included twice."
      );
    }
    var d = window.document;
    var ch: any = function () {
      ch.c(arguments);
    };
    ch.q = [];
    ch.c = function (args) {
      ch.q.push(args);
    };
    windowAny.ChannelIO = ch;
    function l() {
      if (windowAny.ChannelIOInitialized) {
        return;
      }
      windowAny.ChannelIOInitialized = true;
      var s = document.createElement("script");
      s.type = "text/javascript";
      s.async = true;
      s.src = "https://cdn.channel.io/plugin/ch-plugin-web.js";
      s.charset = "UTF-8";
      var x = document.getElementsByTagName("script")[0];
      x.parentNode.insertBefore(s, x);
    }
    if (document.readyState === "complete") {
      l();
    } else if (windowAny.attachEvent) {
      windowAny.attachEvent("onload", l);
    } else {
      window.addEventListener("DOMContentLoaded", l, false);
      window.addEventListener("load", l, false);
    }
  }

  setMe(me: UserInfo) {
    ChannelTalkService.me = me;
  }

  boot() {
    let me = ChannelTalkService.me;
    if (!windowAny.ChannelIO || !me) return;

    log(LogLevel.UI_LIFECYCLE, "ChannelTalkService:boot", me);

    let settings = {
      pluginKey: "6fb9029c-e5d0-4aa8-8bed-6dd8a9db70fb", //please fill with your plugin key
      memberId: me.id, //fill with user name
      profile: {
        name: me.name, //fill with user name
        nickname: me.nickname, //fill with user name
        mobileNumber: me.phone, //fill with user phone number
        email: me.email,
        avatarUrl: me.profileUrl,
        referrer: "",
        type: UserWorkTypeName[me.workType],
        pharmacy: me.workPlaceName,
        address: me.workPlaceAddress,
        jobpostStatus: me.jobpostStatus,
        jobpostDistance: me.jobpostDistance,
        jobpostAddress: me.homeAddress,
        lastJobpostAccess: me.lastJobPostAt,
        licensedAt: me.licensedAt,
        premiumType: me.premiumType,
      },
      customLauncherSelector: "#chanellTalkButton",
      hideChannelButtonOnBoot: true,
      mobileMessengerMode: "iframe",
    };
    windowAny.ChannelIO("boot", settings);
    // windowAny.ChannelIO('showChannelButton');
    ChannelTalkService.on = true;
  }

  shutdown() {
    if (!windowAny.ChannelIO) return;
    ChannelTalkService.on = false;
    log(LogLevel.UI_LIFECYCLE, "ChannelTalkService:shutdown");
    // windowAny.ChannelIO('hideChannelButton');
    windowAny.ChannelIO("shutdown");
  }

  static track = (title: string, data: any = {}) => {
    if (
      ChannelTalkService.instance == null ||
      !ChannelTalkService.on ||
      !windowAny.ChannelIO
    )
      return;

    windowAny.ChannelIO("track", title, data);
  };

  static show = () => {
    if (
      ChannelTalkService.instance == null ||
      !windowAny.ChannelIO ||
      ChannelTalkService.isShown
    ) {
      log(
        LogLevel.UI_LIFECYCLE,
        "ChannelTalkService:show failed",
        ChannelTalkService.instance,
        ChannelTalkService.on,
        ChannelTalkService.isShown
      );
      return;
    }

    if (!ChannelTalkService.on) ChannelTalkService.instance.boot();
    log(LogLevel.UI_LIFECYCLE, "ChannelTalkService:show");
    windowAny.ChannelIO("showMessenger");
    windowAny.ChannelIO("onHideMessenger", () => {
      ChannelTalkService.isShown = false;
    });
    ChannelTalkService.isShown = true;
  };

  static hide = () => {
    if (
      ChannelTalkService.instance == null ||
      !ChannelTalkService.on ||
      !windowAny.ChannelIO ||
      !ChannelTalkService.isShown
    ) {
      log(
        LogLevel.UI_LIFECYCLE,
        "ChannelTalkService:hide failed",
        ChannelTalkService.instance,
        ChannelTalkService.on,
        ChannelTalkService.isShown
      );
      return;
    }
    log(LogLevel.UI_LIFECYCLE, "ChannelTalkService:hide");
    ChannelTalkService.isShown = false;
    windowAny.ChannelIO("hideMessenger");
  };
}

export default ChannelTalkService;
