import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import styled, { StyledComponent } from "styled-components";
import Button from "../../atoms/Button";
import TabBar from "../../molecules/TabBar";
import FloatingButton from "../../atoms/FloatingButton";
import { COLOR_SYSTEM } from "../../design/design-system";
import JobPostCard from "../JobPostCard";
import TopAppBar from "../../molecules/TopAppBar/TopAppBar";
import { JobListState, JobListType } from "../../../store/jobpost/types";
import { log, LogLevel } from "../../../utils/LogUtil";
import {
  AllInOnePartnerInfo,
  CompanyTypeName,
  UserInfo,
  UserWorkType,
  UserWorkTypeName,
} from "../../../models/Model.User";
import ReactHtmlParser from "react-html-parser";
import Text from "../../atoms/Text";
import Popup from "../../molecules/Popup";
import { withRouter, RouteComponentProps } from "react-router-dom";
import BottomSheet from "../../molecules/BottomSheet/BottomSheet";
import {
  displayTabbar,
  hideTabbar,
  isMobile,
} from "../../../utils/JobPostUtil";
import Badge from "../../atoms/Badge/Badge";
import { getGlobal, setGlobal } from "../../../utils/GlobalUtil";
import ABTestUtil, { ABTestFeature } from "../../../utils/ABTestUtil";
import ChannelTalkService from "../../../utils/ChannelTalkService";
import { throttle } from "lodash";
import Spinner from "../../atoms/Spinner";
import AnalyticsUtil from "../../../utils/AnalyticsUtil";

import { Header, Static } from "../../atoms/Layout";
import Scroll from "../../cell/Scroll";
import Icon from "../../atoms/Icon";
import Tag from "../../atoms/Tag";
import StringUtil from "../../../utils/StringUtil";

export interface AllInOnePartnerCardProps {
  partner: AllInOnePartnerInfo;
  onClick: () => void;
}

const CardWrapper: StyledComponent<"div", React.FC> = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;

  .allinone-partner-card-logo {
    min-width: 60px;
    min-height: 60px;
    width: 60px;
    height: 60px;
    object-fit: contain;
    border: 1px solid #e4ebed;
    border-radius: 30px;
    margin-right: 20px;
  }
`;
const AllInOnePartnerCard: React.FC<
  AllInOnePartnerCardProps & RouteComponentProps
> = ({ partner, onClick }) => {
  if (!partner) return null;
  try {
    return (
      <CardWrapper onClick={onClick}>
        <img
          className="allinone-partner-card-logo"
          src={StringUtil.convertFilePath(partner.logoImageUrl)}
          onError={(e) => {
            let targetAny: any = e.target;
            targetAny.src = "/images/profile_p.jpg";
          }}
        />

        <div className="common-flex-grow">
          <div className="allinone-partner-card-title">
            <Text textType="Body1Bold" color={COLOR_SYSTEM.get("Gray")[700]}>
              {partner.companyProfileName || partner.companyName}
            </Text>
          </div>
          {partner.introductionText && (
            <div className="allinone-partner-card-head-container">
              <Text
                textType="Body2"
                color={COLOR_SYSTEM.get("Gray")[800]}
                numberOfLines={3}
              >
                {ReactHtmlParser(
                  partner.introductionText.replace(/\n/gi, "<br/>")
                )}
              </Text>
            </div>
          )}
        </div>
      </CardWrapper>
    );
  } catch (error) {
    AnalyticsUtil.event(AnalyticsUtil.TYPE_ALL, "UI_Rendering_Error", "에러", {
      page: "AllInOnePartnerCardTemplate",
      errorMessage: error,
    });
  }
};

export default withRouter(AllInOnePartnerCard);
