import React, { Component } from "react";
import "./ProfileSimple.css";
import { IonInput } from "@ionic/react";
import { log, LogLevel } from "../utils/LogUtil";
import ProfileImage from "./ProfileImage";
import Tag from "./atoms/Tag";
import { Flex, Static } from "./atoms/Layout";
import Avatar from "./atoms/Avatar";
import Text from "./atoms/Text";
import { COLOR_SYSTEM } from "./design/design-system";
import { getDateTimeString } from "../utils/TimeUtil";
import Button from "./atoms/Button";
import Badge from "./atoms/Badge/Badge";
import withBottomSheet from "../hoc/withBottomSheet";
import { BottomSheetState } from "../store/modal/bottomsheet";
import Icon from "./atoms/Icon";
import {
  AllInOnePartnerInfo,
  CompanyTypeName,
  UserLevel,
} from "../models/Model.User";
import { fetchAPI } from "../utils/APIUtil";
import { getGlobal, GlobalKey } from "../utils/GlobalUtil";
import API from "../API.json";
import { RootState } from "../store";
import { UIPopupType } from "../store/ui/types";
import { connect } from "react-redux";
import { PremiumType } from "../models/Model.User.Premium";

type Props = ReturnType<typeof mapStateToProps> & {
  boardId?: number;
  isRoot?: boolean;
  name?: string;
  customName?: string;
  premiumType?: PremiumType;
  userId?: number;
  profileUrl?: string;
  anonymous?: boolean;
  label?: string;
  withProfile?: boolean;
  editable?: boolean;
  isNice?: boolean;
  blocked?: boolean;
  anonymousName?: any;
  partner?: AllInOnePartnerInfo;
  boardsCategoriesName?: string;
  isUpdatedFromLastView?: boolean;
  onPress?: (id: number) => void;
  onPressPartner?: (id: string) => void;
  onCustomNameChanged?: (customName: string) => void;
  onClickMore?: React.MouseEventHandler<HTMLDivElement>;
  bottomSheet?: {
    show: (options?: Partial<BottomSheetState>) => void;
    close: () => void;
  };
};

type States = {
  customName: string;
};

enum QuestionReports {
  Delete = 5,
  Irrelevant = 4,
  HateSpeech = 1,
  Spam = 2,
}

class ProfileSimple extends Component<Props, States> {
  nameInput = null;

  constructor(props: Props) {
    super(props);
    let customName = this.props.customName;
    if (!customName) customName = this.props.name;
    this.state = {
      customName,
    };
  }

  componentWillUpdate(nextProps) {
    if (this.props.customName != nextProps.customName) {
      this.setState({ customName: nextProps.customName });
    }
  }

  onClick = () => {
    log(LogLevel.UI_EVENT, "ProfileSimple:onClick", this.props);
    if (!this.props.anonymous && this.props.userId > 0 && this.props.onPress)
      this.props.onPress(this.props.userId);
  };

  onCustomNameChange = (event: CustomEvent) => {
    // console.dir(event);
    let customName = event.detail.value;
    this.setState({ customName });
    if (this.props.onCustomNameChanged)
      this.props.onCustomNameChanged(customName);
  };

  handleClickReport = (report: QuestionReports) => async () => {
    await fetchAPI(
      API.ACTION_REPORT,
      `${report}/${this.props.boardId}`,
      null,
      null,
      getGlobal(GlobalKey.TOKEN)
    );
    this.props.toast.show("신고처리가 완료되었습니다.");
    this.props.bottomSheet.close();
  };

  render() {
    if (
      !this.props.name &&
      !this.props.userId &&
      !this.props.profileUrl &&
      !this.props.anonymous &&
      !this.props.label &&
      !this.props.customName
    )
      return null;

    let myPost;
    if (
      this.props.editable &&
      (!this.props.withProfile || this.props.anonymous)
    )
      myPost = (
        <Tag color="Black" variant="Contained">
          내가 쓴 글
        </Tag>
      );

    let isNice;
    if (this.props.isNice && (!this.props.withProfile || this.props.partner))
      isNice = (
        <Tag color="Orange" variant="Contained">
          추천콘텐츠
        </Tag>
      );

    let label;
    if (this.props.label)
      label = (
        <Text textType="Caption" color={COLOR_SYSTEM.get("Gray")[400]}>
          {this.props.label}
        </Text>
      );

    let rvalue;
    if (this.props.withProfile) {
      if (this.props.isRoot) {
        if (this.props.partner) {
          rvalue = (
            <Static>
              <Flex
                alignItems="center"
                justifyContent="space-between"
                style={{ marginBottom: "24px" }}
              >
                <Flex alignItems="center" gap="8px">
                  {myPost}
                  {isNice}
                  <Tag variant="Tinted" color="Gray">
                    {CompanyTypeName[this.props.partner.companyType]}
                  </Tag>
                </Flex>
                {label}
                {this.props.onClickMore && (
                  <Button
                    color="Secondary"
                    size="Medium"
                    variant="Ghost"
                    type="Icon"
                    icon="More"
                    onClick={this.props.onClickMore}
                  />
                )}
              </Flex>
              <Flex
                alignItems="center"
                gap="8px"
                onClick={() => this.props.onPressPartner(this.props.partner.id)}
              >
                <Avatar
                  size="Medium"
                  url={
                    this.props.partner.logoImageUrl || "/images/profile_p.jpg"
                  }
                />
                <Text
                  textType="Body1SemiBold"
                  color={COLOR_SYSTEM.get("Gray")[800]}
                  style={{ marginLeft: "8px", marginRight: "4px" }}
                >
                  {this.props.partner.companyProfileName ||
                    this.props.partner.companyName}
                </Text>
              </Flex>
            </Static>
          );
        } else {
          let displayName = this.props.name;
          if (this.props.userId == -1) {
            if (this.props.customName) displayName = this.props.customName;
            else if (this.props.name) displayName = this.props.name;
            else {
              displayName = "익명약사";
            }
          }
          if (this.props.anonymous) {
            if (this.props.customName) displayName = this.props.customName;
            else {
              displayName = "익명약사";
              log(
                LogLevel.UI_LIFECYCLE,
                "ProfileSimple:render",
                this.props.anonymous,
                this.props.userId,
                this.props.anonymousName
              );
              if (
                this.props.anonymousName &&
                this.props.anonymousName[this.props.userId]
              )
                displayName += this.props.anonymousName[this.props.userId];
            }
          }

          // let name = (
          //   <span className="profile-simple-info-name">
          //     {displayName}
          //     {this.props.premiumType && (
          //       <img
          //         style={{
          //           width: "16px",
          //           height: "16px",
          //           marginLeft: "4px",
          //         }}
          //         src="/images/badge-donation.png"
          //       />
          //     )}
          //     {this.props.blocked ? (
          //       <span className="profile-simple-info-name-blocked">(차단)</span>
          //     ) : (
          //       ""
          //     )}
          //   </span>
          // );
          // if (this.props.onCustomNameChanged) {
          //   name = (
          //     <IonInput
          //       ref={(ref) => {
          //         this.nameInput = ref;
          //       }}
          //       class="profile-simple-name-input"
          //       value={this.state.customName}
          //       onIonChange={this.onCustomNameChange}
          //       onClick={(e) => {
          //         e.stopPropagation();
          //       }}
          //     />
          //   );
          // }

          // log(LogLevel.NONE, "SimpleProfile.render: ", this.props);
          // <div className="profile-simple-container" onClick={this.onClick}>
          //   <ProfileImage
          //     className="profile-simple-image"
          //     profileUrl={this.props.profileUrl}
          //     anonymous={this.props.anonymous}
          //   />
          //   <div className="profile-simple-info-container">
          //     {name}
          //     {label}
          //   </div>
          //   {myPost}
          // </div>
          rvalue = (
            <Static>
              <Flex
                alignItems="center"
                justifyContent="space-between"
                style={{ marginBottom: "24px" }}
              >
                <Flex alignItems="center" gap="8px">
                  {myPost}
                  {isNice}
                  <Tag variant="Tinted" color="Gray">
                    {this.props.boardsCategoriesName}
                  </Tag>
                </Flex>
                {label}
                {this.props.onClickMore && (
                  <Button
                    color="Secondary"
                    size="Medium"
                    variant="Ghost"
                    type="Icon"
                    icon="More"
                    onClick={this.props.onClickMore}
                  />
                )}
              </Flex>
              <Flex alignItems="center" gap="8px" onClick={this.onClick}>
                <Avatar size="Medium" url={this.props.profileUrl} />
                <Text
                  textType="Body1SemiBold"
                  color={COLOR_SYSTEM.get("Gray")[800]}
                  style={{ marginLeft: "8px", marginRight: "4px" }}
                >
                  {displayName}
                </Text>
                {this.props.premiumType && (
                  <img
                    src="/images/sprout2.png"
                    style={{
                      width: "16px",
                      height: "16px",
                      objectFit: "cover",
                    }}
                  />
                )}
                {this.props.isUpdatedFromLastView && (
                  <Badge color="Red" content="N" />
                )}
                <br />
              </Flex>
            </Static>
          );
        }
      } else {
        if (this.props.partner) {
          rvalue = (
            <Flex alignItems="center" justifyContent="space-between">
              <Flex
                alignItems="center"
                gap="8px"
                onClick={() => this.props.onPressPartner(this.props.partner.id)}
              >
                <Avatar
                  size="Medium"
                  url={
                    this.props.partner.logoImageUrl || "/images/profile_p.jpg"
                  }
                />
                <Text
                  textType="Body1SemiBold"
                  color={COLOR_SYSTEM.get("Gray")[800]}
                  style={{ marginLeft: "8px", marginRight: "4px" }}
                >
                  {this.props.partner.companyProfileName ||
                    this.props.partner.companyName}
                </Text>
                <Tag size="Small" variant="Ghost" color="Blue">
                  {CompanyTypeName[this.props.partner.companyType]}
                </Tag>
                <br />
              </Flex>
              <Flex alignItems="center">
                {label}
                {this.props.onClickMore && (
                  <Button
                    color="Secondary"
                    size="Medium"
                    variant="Ghost"
                    type="Icon"
                    icon="More"
                    onClick={this.props.onClickMore}
                  />
                )}
              </Flex>
            </Flex>
          );
        } else {
          let displayName = this.props.name;
          if (this.props.userId == -1) {
            if (this.props.customName) displayName = this.props.customName;
            else if (this.props.name) displayName = this.props.name;
            else {
              displayName = "익명약사";
            }
          }
          if (this.props.anonymous) {
            if (this.props.customName) displayName = this.props.customName;
            else {
              displayName = "익명약사";
              log(
                LogLevel.UI_LIFECYCLE,
                "ProfileSimple:render",
                this.props.anonymous,
                this.props.userId,
                this.props.anonymousName
              );
              if (
                this.props.anonymousName &&
                this.props.anonymousName[this.props.userId]
              )
                displayName += this.props.anonymousName[this.props.userId];
            }
          }

          let name = (
            <span className="profile-simple-info-name">
              {displayName}
              {this.props.blocked ? (
                <span className="profile-simple-info-name-blocked">(차단)</span>
              ) : (
                ""
              )}
            </span>
          );
          if (this.props.onCustomNameChanged) {
            name = (
              <IonInput
                ref={(ref) => {
                  this.nameInput = ref;
                }}
                class="profile-simple-name-input"
                value={this.state.customName}
                onIonChange={this.onCustomNameChange}
                onClick={(e) => {
                  e.stopPropagation();
                }}
              />
            );
          }

          log(LogLevel.NONE, "SimpleProfile.render: ", this.props);
          // <div className="profile-simple-container" onClick={this.onClick}>
          //   <ProfileImage
          //     className="profile-simple-image"
          //     profileUrl={this.props.profileUrl}
          //     anonymous={this.props.anonymous}
          //   />
          //   <div className="profile-simple-info-container">
          //     {name}
          //     {label}
          //   </div>
          //   {myPost}
          // </div>
          rvalue = (
            <Flex alignItems="center" justifyContent="space-between">
              <Flex alignItems="center" gap="8px" onClick={this.onClick}>
                <Avatar size="Medium" url={this.props.profileUrl} />
                <Text
                  textType="Body1SemiBold"
                  color={COLOR_SYSTEM.get("Gray")[800]}
                  style={{ marginLeft: "8px", marginRight: "4px" }}
                >
                  {displayName}
                </Text>
                {this.props.premiumType && (
                  <img
                    src="/images/sprout2.png"
                    style={{
                      width: "16px",
                      height: "16px",
                      objectFit: "cover",
                    }}
                  />
                )}
                {this.props.isUpdatedFromLastView && (
                  <Badge color="Red" content="N" />
                )}
              </Flex>
              <Flex alignItems="center">
                {label}
                {this.props.onClickMore && (
                  <Button
                    color="Secondary"
                    size="Medium"
                    variant="Ghost"
                    type="Icon"
                    icon="More"
                    onClick={this.props.onClickMore}
                  />
                )}
              </Flex>
            </Flex>
          );
        }
      }
    } else {
      rvalue = (
        <Flex alignItems="center" justifyContent="space-between">
          <Flex alignItems="center" gap="8px">
            {myPost}
            {isNice}
            {this.props.boardsCategoriesName && (
              <Tag variant="Tinted" color="Gray">
                {this.props.boardsCategoriesName}
              </Tag>
            )}
          </Flex>
          {label}
        </Flex>
      );
    }
    return rvalue;
  }
}

const mapStateToProps = (state: RootState) => ({
  me: state.user.me,
  toast: state.ui.popups[UIPopupType.TOAST_POPUP],
});

export default connect(mapStateToProps)(withBottomSheet(ProfileSimple));
