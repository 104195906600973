import React, { Component } from "react";
import { connect } from "react-redux";
import { RootState, actions } from "../../../store";
import { RouteComponentProps } from "react-router";
import {
  IonInput,
  IonText,
  IonSelect,
  IonSelectOption,
  IonHeader,
  IonIcon,
  IonButtons,
  IonMenuButton,
  IonList,
  IonSegmentButton,
  IonButton,
  IonSearchbar,
  IonContent,
  IonRefresher,
  IonRefresherContent,
  IonFab,
  IonFabList,
  IonFabButton,
  IonAlert,
  IonBadge,
  IonLabel,
} from "@ionic/react";
import "./../../Admin.scss";
import { log, LogLevel } from "../../../utils/LogUtil";
import { setGlobal, GlobalKey, getGlobal } from "../../../utils/GlobalUtil";
import { fetchAPI } from "../../../utils/APIUtil";
import Truncate from "react-truncate";
import {
  SeminarLecture,
  SeminarLectureAttribute,
} from "../../../models/Model.Seminar";
import { UIPopupType } from "../../../store/ui/types";
import { STATUS_CODES } from "http";
import { idText, StringLiteralLike } from "typescript";
import { getDateStringFromToday } from "../../../utils/TimeUtil";
import { timingSafeEqual } from "crypto";
import ReactHtmlParser from "react-html-parser";
import { getOS } from "../../../utils/DeviceUtil";
import { isThisHour } from "date-fns";
import * as API from "../../../API.json";
import { BoardContent, DailyQuiz } from "../../../models/Model.Board";
import ToggleButton from "../../../components/atoms/ToggleButton/ToggleButton";
import Button from "../../../components/atoms/Button";
import { Static } from "../../../components/atoms/Layout";

const AdminAPI = {
  DAILY_QUIZ_LIST: {
    method: "GET",
    path: "/admin/quiz/daily/list",
    contentType: "application/json",
  },
};

type Props = typeof mapDispatchToProps &
  ReturnType<typeof mapStateToProps> & {
    onSelect: (dailyQuiz: DailyQuiz) => void;
    dailyQuiz: DailyQuiz;
  };

type State = {
  list: DailyQuiz[];
  dateStart: string;
  dateStartWarn: boolean;
  dateEnd: string;
  dateEndWarn: boolean;
};

class DailyQuizSelect extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    let score = getGlobal(GlobalKey.ADMIN_DIGEST_SCORE, true);
    let scoreBoard = score.score;
    if (!scoreBoard) scoreBoard = [10, 10, 0, 0, 0, 10, 1, 10000];

    let resultGap = score.resultGap;
    if (!resultGap) resultGap = 7;

    while (scoreBoard.length <= 7) scoreBoard.push(10000);

    this.state = {
      list: [],
      dateStart: getDateStringFromToday({ weeks: -2 }),
      dateStartWarn: false,
      dateEnd: getDateStringFromToday({ weeks: 2 }),
      dateEndWarn: false,
    };
  }
  loading = false;

  componentDidMount() {
    this.fetchList();
  }

  fetchList = async () => {
    if (this.loading) return;

    this.loading = true;
    this.props.progressPopup.show();

    let result = await fetchAPI(
      AdminAPI.DAILY_QUIZ_LIST,
      "",
      {
        startDate: this.state.dateStart,
        endDate: this.state.dateEnd,
        includeStatistics: true,
      },
      null,
      getGlobal(GlobalKey.TOKEN)
    );
    if (result && !result.error && result.data && result.data.length > 0) {
      this.setState({
        list: result.data,
      });
    } else {
      this.setState({ list: [] });
    }
    this.props.progressPopup.hide();

    this.loading = false;
    log(LogLevel.UI_EVENT, "DailyQuizSelect:fetchList", result);
  };

  onSelect = (detail) => {
    this.props.onSelect(detail);
  };

  refresh = (dailyQuiz: DailyQuiz) => {
    log(LogLevel.UI_EXCEPTION, "Admin:DailyQuizSelect:refresh", dailyQuiz);
    this.fetchList();
  };

  onDateChange = (e, field) => {
    let dateString = e.target.value;
    let dateWarn = false;
    try {
      let date = Date.parse(dateString);
      if (!date) {
        log(
          LogLevel.UI_EXCEPTION,
          "Admin:DailyQuizSelect:onDateEndChange",
          dateString
        );
        dateWarn = true;
      } else {
        log(
          LogLevel.UI_DATA_LOAD,
          "Admin:DailyQuizSelect:onDateEndChange",
          dateString,
          date
        );
      }
    } catch (err) {
      log(
        LogLevel.UI_EXCEPTION,
        "Admin:DailyQuizSelect:onDateEndChange",
        dateString,
        err
      );
      dateWarn = true;
    }
    let newState: any = {};
    (newState[field] = dateString),
      (newState[field + "Warn"] = dateWarn),
      this.setState(newState);
  };

  renderDailyQuizzes() {
    return (
      <table id="admin-table" className="admin-table">
        <thead className="admin-table-fixed-header">
          <tr>
            <td className="admin-table-label-x">ID </td>
            <td className="admin-table-label-x">날짜</td>
            <td className="admin-table-label-x">제목</td>
            <td className="admin-table-label-x">푼사람수</td>
          </tr>
        </thead>
        <tbody id="admin-table-body">
          {this.state.list.map((item: DailyQuiz, index) => {
            return (
              <tr
                key={index.toString()}
                className={
                  this.props.dailyQuiz && this.props.dailyQuiz.id == item.id
                    ? " admin-table-value-selected"
                    : ""
                }
                onClick={() => this.onSelect(item)}
              >
                <td className={"admin-table-value"}>{item.id}</td>
                <td className={"admin-table-value"}>
                  {item.date ? getDateStringFromToday({ date: item.date }) : ""}
                </td>
                <td className={"admin-table-value"}>{item.title}</td>
                <td className={"admin-table-value"}>{item.tryCnt}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  }

  render() {
    log(
      LogLevel.UI_LIFECYCLE,
      "DailyQuizSelect.render",
      this.props,
      this.state
    );

    return (
      <div className="admin-full-container">
        <div className="common-container-row-wrap admin-margin-bottom">
          <div className="admin-title">기간</div>
          <input
            className={this.state.dateStartWarn ? "common-color-caution" : ""}
            placeholder="시작일시"
            value={this.state.dateStart}
            onChange={(e) => this.onDateChange(e, "dateStart")}
          />
          <div>~</div>
          <input
            className={this.state.dateEndWarn ? "common-color-caution" : ""}
            placeholder="시작일시"
            value={this.state.dateEnd}
            onChange={(e) => this.onDateChange(e, "dateEnd")}
          />
        </div>
        <div className="common-container-row-wrap admin-margin-bottom">
          <Button
            onClick={() => {
              this.setState({
                dateStart: getDateStringFromToday({ weeks: -2 }),
                dateEnd: getDateStringFromToday({ days: 1, weeks: 2 }),
                dateStartWarn: false,
                dateEndWarn: false,
              });
            }}
            size={"Small"}
            type={"Text"}
            variant={"Outlined"}
            color={"Secondary"}
          >
            전후 2주
          </Button>
          <Button
            onClick={() => {
              this.setState({
                dateStart: getDateStringFromToday({ weeks: -4 }),
                dateEnd: getDateStringFromToday({ days: 1 }),
                dateStartWarn: false,
                dateEndWarn: false,
              });
            }}
            size={"Small"}
            type={"Text"}
            variant={"Outlined"}
            color={"Secondary"}
          >
            전1개월
          </Button>
          <Button
            onClick={() => {
              this.setState({
                dateStart: getDateStringFromToday({ months: -1, day: 1 }),
                dateEnd: getDateStringFromToday({
                  day: 1,
                  days: -1,
                }),
                dateStartWarn: false,
                dateEndWarn: false,
              });
            }}
            size={"Small"}
            type={"Text"}
            variant={"Outlined"}
            color={"Secondary"}
          >
            지난달
          </Button>
          <Button
            onClick={() => {
              this.setState({
                dateStart: getDateStringFromToday({ day: 1 }),
                dateEnd: getDateStringFromToday({
                  months: 1,
                  day: 1,
                  days: -1,
                }),
                dateStartWarn: false,
                dateEndWarn: false,
              });
            }}
            size={"Small"}
            type={"Text"}
            variant={"Outlined"}
            color={"Secondary"}
          >
            이번달
          </Button>

          <Static customStyle={{ flexGrow: 1 }} />
          <Button
            onClick={() => {
              this.fetchList();
            }}
            size={"Small"}
            type={"Text"}
            variant={"Contained"}
            color={"Primary"}
          >
            불러오기
          </Button>
        </div>
        <div className="common-container">
          {this.renderDailyQuizzes()}
          <div style={{ height: "100px" }} />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  progressPopup: state.ui.popups[UIPopupType.WAITING_POPUP],
  toastPopup: state.ui.popups[UIPopupType.TOAST_POPUP],
  confirmPopup: state.ui.popups[UIPopupType.CONFIRM_POPUP],
});

const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true,
})(DailyQuizSelect);
