import React, { Component } from "react";
import { connect } from "react-redux";
import { RootState, actions } from "../store";
import { withRouter, RouteComponentProps } from "react-router";
import { IonIcon, IonButton, IonActionSheet, IonToast } from "@ionic/react";
import "./ViewerBody.css";
import "./../Common.css";
import BoardList from "./../components/BoardList";
import composeIcon from "./../assets/icon/compose.svg";
import * as API from "./../API.json";
import { MY_ADDRESS } from "./../config.json";
import { fetchAPI } from "./../utils/APIUtil";
import ReactHtmlParser from "react-html-parser";
import { getDateTimeString } from "./../utils/TimeUtil";
import LinkMeta from "./LinkMeta";
import qustionIcon from "./../assets/image/question.png";
import likeIcon from "./../assets/icon/like.svg";
import dislikeIcon from "./../assets/icon/dislike.svg";
import scrapIcon from "./../assets/icon/bookmark.svg";
import kakaoIcon from "./../assets/icon/kakao.svg";
import ActionButton from "./ActionButton";
import commentIcon from "./../assets/icon/comment.svg";
import drugIcon from "./../assets/icon/drug.svg";
import ProfileSimple from "./ProfileSimple";
import {
  BoardContent,
  BoardComment,
  Reference,
  BoardAttribute,
} from "./../models/Model.Board";
import { getGlobal, GlobalKey, setGlobal } from "./../utils/GlobalUtil";
import { KeywordType, Keyword } from "../models/Model.Medicine";
import { BoardType } from "./../store/board/types";
import { log, LogLevel } from "../utils/LogUtil";
import BoardUtil from "../utils/BoardUtil";
import StringUtil from "../utils/StringUtil";
import { loadState } from "../api";
import ViewerComments from "./ViewerComments";
import Attachment from "./Attachment";
import { UIPopupType, UIServiceType } from "../store/ui/types";
import { async } from "q";
import {
  AllInOnePartnerInfo,
  UserLevel,
  UserNudgeStages,
  UserNudgeState,
} from "../models/Model.User";
import copy from "copy-to-clipboard";
import ComponentGenerator from "../ivcomponent/ComponentGenerator";
import AnalyticsUtil from "./../utils/AnalyticsUtil";
import ABTestUtil, { ABTestFeature } from "../utils/ABTestUtil";
import badgeIcon from "./../assets/icon/nice_badge.png";
import SwipeableViews from "react-swipeable-views";
import { Flex, Static } from "./atoms/Layout";
import Text from "./atoms/Text";
import { COLOR_SYSTEM } from "./design/design-system";
import Tag from "./atoms/Tag";
import ToggleButton from "./atoms/ToggleButton/ToggleButton";
import Button from "./atoms/Button";
import withBottomSheet from "../hoc/withBottomSheet";
import { BottomSheetState } from "../store/modal/bottomsheet";
import Icon from "./atoms/Icon";
import blockedContent from "../assets/image/blocked_content.png";
import Badge from "./atoms/Badge/Badge";
import ReactVisibilitySensor from "react-visibility-sensor";
import VideoController from "../component/cell/VideoController";
import Video from "../component/atom/Video";
import styled from "styled-components";
import {
  extractTimeToJTag,
  extractTimeValue,
  getTimerString,
} from "../utils/TimeUtil";

enum QuestionReports {
  Delete = 5,
  Irrelevant = 4,
  HateSpeech = 1,
  Spam = 2,
}

const windowAny: any = window;

const AdminAPI = {
  TOGGLE_NO_NICE: {
    method: "GET",
    path: "/admin/board/toggle/nice",
    contentType: "application/json",
  },
  MOVE: {
    method: "PUT",
    path: "/admin/board/content/category",
    contentType: "application/json",
  },
};

const BodyContent = styled.div`
  margin-top: 16px;
  ol,
  ul,
  dl {
    list-style: auto !important;
    margin-left: 20px;
  }
`;

const SummaryContainer = styled.div`
  display: flex;
  gap: 4px;
  flex-direction: column;
  margin-top: 16px;
  border: 1px solid ${COLOR_SYSTEM.get("Gray")[200]};
  border-radius: 4px;
  padding: 10px 10px 10px 10px;
`;

const styleObject = (styleString: string) => {
  if (!styleString) return {};
  return styleString.split(";").reduce((acc, prop) => {
    const [key, value] = prop.split(":");
    if (key) {
      const formattedKey = key
        .trim()
        .replace(/-./g, (match) => match.charAt(1).toUpperCase());
      acc[formattedKey] = value.trim();
    }
    return acc;
  }, {});
};

type Props = RouteComponentProps &
  typeof mapDispatchToProps &
  ReturnType<typeof mapStateToProps> & {
    postingId?: number;
    posting?: BoardContent;
    key?: string;
    replyIndex?: number;
    noComment?: boolean;
    noEmptySubject?: boolean;
    noViewCnt?: boolean;
    noScrap?: boolean;
    noKakaoShare?: boolean;
    noProfile?: boolean;
    noToolbar?: boolean;
    open?: boolean;
    onContentUpdate?: (posting: BoardContent, index: number) => void;
    findFocus?: any;
    onFindFocusComponent?: (focused: any) => void;
    anonymousNames?: any;
    noNavigate?: boolean;
    adminMode?: boolean;
    rootAuthorId?: number;
    rootAuthorName?: string;
    onCardnewProgress?: (page, total) => void;
    bottomSheet?: {
      show: (options?: Partial<BottomSheetState>) => void;
      close: () => void;
    };
    partner?: AllInOnePartnerInfo;
    isOnlyQuestion?: boolean;
  };

type State = {
  showMenuPopover: boolean;
  showDeletePopover: boolean;
  showReportPopover: boolean;
  cardviewIndex: number;
};

class ViewerBody extends Component<Props, State> {
  content: BoardContent;
  advertiserInfo: any;
  anonymousName: any;
  focusRef: any;

  handleClickReportReason = (reportReason: QuestionReports) => async () => {
    await this.onReport(reportReason);
    this.props.bottomSheet.close();
  };

  menuScrap = {
    text: "스크랩",
    cssClass: "common-menu-normal",
    handler: () => {
      log(LogLevel.UI_ACTION, "Menu Scrap clicked");
      this.onScrap();
    },
  };

  menuClipboard = {
    text: "주소복사",
    cssClass: "common-menu-normal",
    handler: () => {
      log(LogLevel.UI_ACTION, "Menu Clipboard clicked");
      this.onClipboard();
    },
  };

  menuKakaoShare = {
    text: "카톡공유",
    cssClass: "common-menu-normal",
    handler: () => {
      log(LogLevel.UI_ACTION, "Menu Kakao clicked");
      this.onKakaoShare();
    },
  };

  menuModify = {
    text: "수정",
    cssClass: "common-menu-normal",
    handler: () => {
      log(LogLevel.UI_ACTION, "Menu Modify clicked");
      this.onModify();
    },
  };

  menuDelete = {
    text: "삭제",
    cssClass: "common-menu-red",
    handler: () => {
      log(LogLevel.UI_ACTION, "Menu Delete clicked");
      this.setState({ showDeletePopover: true });
      this.popupBackkey("showDeletePopover");
      this.props.bottomSheet.close();
    },
  };

  menuAdminEnableNice = {
    text: "추천활성화(관리자)",
    cssClass: "common-menu-hightlight",
    handler: () => {
      log(LogLevel.UI_ACTION, "Menu Modify clicked");
      this.onToggleNice();
    },
  };

  menuAdminDisableNice = {
    text: "추천금지(관리자)",
    isAdmin: true,
    cssClass: "common-menu-red",
    handler: () => {
      log(LogLevel.UI_ACTION, "Menu Modify clicked");
      this.onToggleNice();
    },
  };

  menuAdminMove = {
    text: "게시판 이동",
    isAdmin: true,
    cssClass: "common-menu-normal",
    handler: () => {
      log(LogLevel.UI_ACTION, "Menu Modify clicked");
      this.onMoveCategory();
      this.props.bottomSheet.close();
    },
  };

  menuAdminModify = {
    text: "수정하기",
    isAdmin: true,
    cssClass: "common-menu-normal",
    handler: () => {
      log(LogLevel.UI_ACTION, "Menu Modify clicked");
      this.onModify();
    },
  };

  menuAdminKeyword = {
    text: "키워드추출(관리자)",
    isAdmin: true,
    cssClass: "common-menu-hightlight",
    handler: () => {
      log(LogLevel.UI_ACTION, "Menu Modify clicked");
      this.onKeywording();
    },
  };

  menuAdminDelete = {
    text: "삭제하기",
    textIcon: {
      position: "left",
      name: "Trash",
      width: 24,
      height: 24,
      color: COLOR_SYSTEM.get("Red")[300],
    },
    isAdmin: true,
    cssClass: "common-menu-red",
    handler: () => {
      log(LogLevel.UI_ACTION, "Menu Delete clicked");
      this.setState({ showDeletePopover: true });
      this.popupBackkey("showDeletePopover");
      this.props.bottomSheet.close();
    },
  };

  menuReport = {
    text: "신고하기",
    isAdmin: false,
    right: (
      <Icon
        name="CaretRight"
        width={24}
        height={24}
        color={COLOR_SYSTEM.get("Gray")[200]}
      />
    ),
    handler: () => {
      // log(LogLevel.UI_ACTION, "Menu Report clicked");
      // this.setState({ showReportPopover: true });
      // this.popupBackkey("showReportPopover");
      this.props.bottomSheet.show({
        title: {
          value: "신고 사유 선택",
          align: "left",
        },
        body: (
          <Flex
            direction="column"
            gap="24px"
            customStyle={{ marginTop: "24px" }}
          >
            <Text
              textType="Body1"
              color={COLOR_SYSTEM.get("Gray")[700]}
              onClick={this.handleClickReportReason(QuestionReports.Delete)}
            >
              글삭튀(답변이 달린 후 내용 삭제)
            </Text>
            <Text
              textType="Body1"
              color={COLOR_SYSTEM.get("Gray")[700]}
              onClick={this.handleClickReportReason(QuestionReports.Irrelevant)}
            >
              게시판 주제와 무관
            </Text>
            <Text
              textType="Body1"
              color={COLOR_SYSTEM.get("Gray")[700]}
              onClick={this.handleClickReportReason(QuestionReports.HateSpeech)}
            >
              폭언, 비속어, 혐오 발언
            </Text>
            <Text
              textType="Body1"
              color={COLOR_SYSTEM.get("Gray")[700]}
              onClick={this.handleClickReportReason(QuestionReports.Spam)}
            >
              홍보, 스팸, 광고
            </Text>
          </Flex>
        ),
      });
    },
  };

  // menuCancel = {
  //   text: "취소",
  //   role: "cancel",
  //   cssClass: "common-menu-normal",
  //   handler: () => {
  //     log(LogLevel.UI_ACTION, "Menu Cancel clicked");
  //   },
  // };

  constructor(props: Props) {
    super(props);
    this.state = {
      showMenuPopover: false,
      showDeletePopover: false,
      showReportPopover: false,
      cardviewIndex: 0,
    };
    this.loadData();
    log(
      LogLevel.UI_LIFECYCLE,
      "ViewerBody.constructor",
      this.state,
      this.props,
      this.content
    );

    if (!this.props.filePath) {
      this.props.loadFilePath();
    }
  }

  videoEl = null;
  loadData(props = this.props) {
    let firstLoad = false;
    if (props.anonymousNames) this.anonymousName = props.anonymousNames;
    if (props.posting) {
      if (!this.content || this.content.id != props.posting.id)
        firstLoad = true;
      this.content = { ...props.posting };
      if (this.content.anonymousNames)
        this.anonymousName = this.content.anonymousNames;
    } else if (props && props.postingId && props.contents[props.postingId]) {
      if (!this.content || this.content.id != props.postingId) firstLoad = true;

      if (props.contents[props.postingId].anonymousNames)
        this.anonymousName = props.contents[props.postingId].anonymousNames;

      if (props.replyIndex == 0 || props.replyIndex) {
        log(
          LogLevel.UI_DATA_LOAD,
          "ViewerBody:loadData: reply ",
          props.contents[props.postingId],
          props.contents[props.postingId].anonymousNames,
          this.anonymousName
        );
        if (
          props.contents[props.postingId].replies &&
          props.contents[props.postingId].replies.length > props.replyIndex
        )
          this.content = {
            ...props.contents[props.postingId].replies[props.replyIndex],
          };
      } else {
        this.content = { ...props.contents[props.postingId] };

        if (firstLoad) {
          if (this.content.commercialType && this.content.advertiserCode) {
            this.advertiserInfo = {
              code: this.content.advertiserCode,
              productCode: this.content.advertiserProductCode,
              contentType:
                this.content.cards && this.content.cards.length
                  ? "CARDNEWS"
                  : "CONTENT",
              contentId: this.content.id,
              contentTitle: this.content.subject,
            };
          } else {
            this.advertiserInfo = null;
          }
          log(
            LogLevel.UI_LIFECYCLE,
            "ViewerBody.loadData advertise first load",
            this.advertiserInfo,
            this.content
          );
          if (this.advertiserInfo) {
            let action =
              this.content.cards && this.content.cards.length
                ? "카드뉴스"
                : "컨텐츠";
            AnalyticsUtil.event(
              AnalyticsUtil.TYPE_ALL,
              "ADVERTISE_VIEW",
              "광고콘텐츠시청",
              {
                type: action,
                ...this.advertiserInfo,
              }
            );
          }
        }
      }
    }

    if (this.content) {
      if (this.content.scrapped) {
        this.menuScrap.text = "스크랩 취소";
      } else {
        this.menuScrap.text = "스크랩";
      }
    }
  }

  componentWillUpdate(nextProps) {
    this.loadData(nextProps);
    log(
      LogLevel.UI_LIFECYCLE,
      "ViewerBody.componentWillUpdate",
      nextProps,
      this.content
    );
  }

  componentDidUpdate(prevProps, prevState) {
    this.loadData();
    log(
      LogLevel.UI_LIFECYCLE,
      "ViewerBody.componentDidUpdate",
      this.props,
      this.content
    );

    if (
      this.props.findFocus != prevProps.findFocus &&
      this.props.findFocus.contentId != prevProps.findFocus.contentId &&
      this.focusRef &&
      this.props.onFindFocusComponent &&
      this.content &&
      this.props.findFocus &&
      this.props.findFocus.contentId &&
      !this.props.findFocus.commentId &&
      this.content.id == this.props.findFocus.contentId
    ) {
      this.props.onFindFocusComponent(this.focusRef.offsetTop);
    }
  }

  componentDidMount() {
    log(
      LogLevel.UI_LIFECYCLE,
      "Viewer.componentDidMount",
      !!this.props.onFindFocusComponent,
      this.props.findFocus
    );
    if (
      this.focusRef &&
      this.props.onFindFocusComponent &&
      this.content &&
      this.props.findFocus &&
      this.props.findFocus.contentId &&
      !this.props.findFocus.commentId &&
      this.content.id == this.props.findFocus.contentId
    ) {
      this.props.onFindFocusComponent(this.focusRef.offsetTop);
    }
  }

  handleChangeIndex = (index: number) => {
    if (index == this.state.cardviewIndex) return;
    if (index < 0 || index >= this.content.cards.length) return;
    if (this.props.onCardnewProgress)
      this.props.onCardnewProgress(index, this.content.cards.length);
    this.setState({ cardviewIndex: index });
  };

  onFindFocusComponent = (offset) => {
    setTimeout(() => {
      if (this.focusRef)
        this.props.onFindFocusComponent(this.focusRef.offsetTop + offset);
    }, 50);
  };

  onClickBannerInPartner = (id: number) => () => {
    log(LogLevel.UI_ACTION, "ViewerBody: onClickBannerInPartner", id);

    AnalyticsUtil.event(
      AnalyticsUtil.TYPE_ALL,
      "PARNTERS_CLICK_BANNER_IN_CONTENT",
      "파트너즈_게시물_배너_클릭",
      {
        게시물id: this.content.id,
        게시판이름: this.content.boardsCategoriesName,
      }
    );

    if (id > 0 && !this.props.noNavigate)
      this.props.history.push("/main/partners");
  };

  onUserPressed = (id: number) => {
    log(
      LogLevel.UI_ACTION,
      "ViewerBody: onUserPressed",
      this.content.isAnonymous,
      id,
      this.props.noNavigate
    );
    // if(this.content.usersId != this.props.me.id)
    if (id > 0 && !this.props.noNavigate)
      this.props.history.push("/user?id=" + id);
  };

  onPartnerPressed = (id: string) => {
    log(LogLevel.UI_ACTION, "ViewerBody: onPartnerPressed", id);
    // if(this.content.usersId != this.props.me.id)
    this.props.history.push("/partners/detail?id=" + id);
  };

  updateContent = (board: BoardType = BoardType.NONE) => {
    //console.log("updateContent", this.props, this.content);
    if (this.props.postingId) {
      if (this.props.replyIndex == 0 || this.props.replyIndex) {
        let parent = this.props.contents[this.props.postingId];
        parent.replies[this.props.replyIndex] = this.content;
        //console.log("updateContent reply", this.content);
        BoardUtil.checksum(parent);
        BoardUtil.countAllLike(parent);
        this.props.updateContent(parent);
      } else {
        //console.log("updateContent main", this.content);
        BoardUtil.checksum(this.content);
        BoardUtil.countAllLike(this.content);
        this.props.updateContent(this.content);
      }
    }
    if (!this.props.open && board != BoardType.NONE)
      this.props.refreshBoards(board);

    if (this.props.onContentUpdate)
      this.props.onContentUpdate(this.content, this.props.replyIndex);
  };

  popupBackkey(name) {
    this.props.backKeyControl.pushListener(() => {
      let newState: any = {};
      newState[name] = false;
      this.setState(newState);
      return true;
    });
  }
  onClickSummary = async () => {
    const { posting } = this.props;

    // const { summary } = posting;
    // const { content } = summary;
    // const { url } = content;
    // window.open(url, '_blank');
  };

  onScrap = async () => {
    if (this.content && this.content.deletedAt) return;
    let scrapped = this.content.scrapped;
    let scrapCnt = this.content.scrapCnt;
    log(LogLevel.NONE, "doScrap", !scrapped);
    let token = getGlobal(GlobalKey.TOKEN);
    let result;
    if (scrapped) {
      result = await fetchAPI(
        API.ACTION_CANCEL_SCRAP,
        this.content.id,
        null,
        null,
        token
      );
      scrapCnt--;
    } else {
      result = await fetchAPI(
        API.ACTION_SCRAP,
        this.content.id,
        null,
        null,
        token
      );
      scrapCnt++;
      // AMPLITUDE
      let body;
      if (this.content.subject) body = this.content.subject.substring(0, 10);
      else if (this.content.bodyText)
        body = this.content.bodyText.substring(0, 10);
      let name = "";
      if (this.content.usersNickname) name = this.content.usersNickname;
      else this.content.usersCustomName;
      name = this.content.usersCustomName;
      AnalyticsUtil.event(
        AnalyticsUtil.TYPE_ALL,
        "CONTENT_SCRAP",
        "게시물_스크랩",
        {
          게시물id: this.content.id,
          게시물내용: body,
          글쓴이id: this.content.usersId,
          글쓴이닉네임: name,
          게시판이름: this.content.boardsCategoriesName,
        }
      );

      if (this.advertiserInfo) {
        AnalyticsUtil.event(
          AnalyticsUtil.TYPE_ALL,
          "ADVERTISE_REACTION",
          "광고고객반응",
          {
            type: "스크랩",
            ...this.advertiserInfo,
          }
        );
      }
    }
    if (result && !result.error) {
      this.content.scrapCnt = scrapCnt;
      this.content.scrapped = !scrapped;
      this.updateContent(BoardType.MY_SCRAP);
    }

    if (ABTestUtil.isTest(ABTestFeature.NUDGE)) {
      if (
        this.content.scrapped &&
        !getGlobal(GlobalKey.NUDGE_READY) &&
        (!this.props.me.nudgeState ||
          this.props.me.nudgeState < UserNudgeState.NUDGED_IGNORED)
      ) {
        let me = this.props.me;
        me.scrapCnt = me.scrapCnt ? me.scrapCnt + 1 : 1;
        this.props.setMe(me);
        let point: number =
          (this.props.me.likeCnt ? this.props.me.likeCnt : 0) +
          (this.props.me.scrapCnt ? this.props.me.scrapCnt : 0);
        if (
          UserNudgeStages[
            this.props.me.nudgeState ? this.props.me.nudgeState : 0
          ] < point
        ) {
          let today = Math.floor(
            (Date.now() + 9 * 60 * 60 * 1000) / (24 * 60 * 60 * 1000)
          );
          if (getGlobal(GlobalKey.NUDGED_LAST_DATE, true) < today) {
            setGlobal(GlobalKey.NUDGED_LAST_DATE, today, true);
            setGlobal(GlobalKey.NUDGE_READY, true);
          }
        }
      }
    }
  };

  onClipboard = () => {
    log(LogLevel.UI_ACTION, "ViewerBody:onClipboard", this.props.location);
    copy(
      MY_ADDRESS + this.props.location.pathname + this.props.location.search
    );
    this.props.toast.show("클립보드에 주소가 저장되었습니다.");
  };

  onModify = () => {
    log(LogLevel.UI_ACTION, "ViewerBody:onModify", this.content);
    if (this.props.adminMode) {
      let url = `/edit?id=${this.content.id}`;
      if (this.content.groupParent) {
        url += `&parent=${this.content.groupParent}`;
      }

      window.open(MY_ADDRESS + url, "_blank");
      return;
    }
    if (
      getGlobal(GlobalKey.OS) !== "browser" &&
      ((this.content.keywords && this.content.keywords.length > 0) ||
        (this.content.imagesInner && this.content.imagesInner.length > 0))
    ) {
      // show "go to PC to modify"
      this.props.confirmPopup.show({
        title: (
          <div className="common-container">
            이 글은 PC 브라우저에서 수정하실 수 있습니다.
          </div>
        ),
        body: (
          <div className="common-container">
            이 글은 PC 브라우저로 www.ymyd.co.kr로 접속하시면 수정이
            가능하십니다.
          </div>
        ),
      });
      return;
    }
    let url = `/edit?id=${this.content.id}`;
    if (this.content.groupParent) {
      url += `&parent=${this.content.groupParent}`;
    }
    this.props.history.push(url);
  };

  onMoveCategory = () => {
    log(LogLevel.UI_ACTION, "ViewerBody:onMoveCategory", this.content);
    let target = BoardType.CONSULT_QNA;
    if (this.content.boardsCategoriesId == BoardType.CONSULT_QNA) {
      target = BoardType.QNA;
    }
    this.props.confirmPopup.show({
      title: (
        <div className="common-container common-color-highlight">
          게시물을 {BoardType[target]}으로 이동하시겠습니까?
        </div>
      ),
      iconImage: null,
      doneText: "이동",
      cancelText: "취소",
      onDone: async () => {
        await fetchAPI(
          AdminAPI.MOVE,
          "",
          null,
          { id: this.content.id, boardsCategoriesId: target },
          getGlobal(GlobalKey.TOKEN)
        );
        this.props.loadContent(this.content.id);
        this.props.refreshBoards();
        this.props.history.goBack();
      },
    });
    return;
  };

  onToggleNice = async () => {
    log(LogLevel.UI_ACTION, "ViewerBody:onToggleNice", this.content);
    if (this.content.noNice) {
      await fetchAPI(
        AdminAPI.TOGGLE_NO_NICE,
        "",
        { id: this.content.id },
        null,
        getGlobal(GlobalKey.TOKEN)
      );
    } else {
      this.props.confirmPopup.show({
        title: (
          <div className="common-container common-color-highlight">
            추천을 금지하시겠습니까?
          </div>
        ),
        iconImage: null,
        doneText: "금지",
        cancelText: "취소",
        onDone: async () => {
          await fetchAPI(
            AdminAPI.TOGGLE_NO_NICE,
            "",
            { id: this.content.id },
            null,
            getGlobal(GlobalKey.TOKEN)
          );
          this.props.loadContent(this.content.id);
        },
      });
    }
  };

  onKeywording = () => {
    window.open(MY_ADDRESS + "/admin/board?id=" + this.content.id, "_blank");
  };

  onDelete = async () => {
    log(LogLevel.UI_ACTION, "ViewerBody:onDelete", this.content);
    let result = await fetchAPI(
      API.BOARD_DELETE,
      this.content.id,
      null,
      null,
      getGlobal(GlobalKey.TOKEN)
    );
    if (!result.error) {
      if (this.props.postingId != this.content.id)
        this.props.loadContent(this.props.postingId);
      else {
        this.props.refreshBoards(this.content.boardsCategoriesId);
        this.props.history.goBack();
      }
    }
  };

  onReport = async (type: number) => {
    log(LogLevel.UI_ACTION, "ViewerBody:onReport", this.content, type);
    let result = await fetchAPI(
      API.ACTION_REPORT,
      type + "/" + this.content.id,
      null,
      null,
      getGlobal(GlobalKey.TOKEN)
    );
    this.props.toast.show("신고처리가 완료되었습니다.");
    // ## TODO - 신고후처리
  };

  onComment = async (id: number, comment: BoardComment) => {
    comment.boardsContentsId = id;
    comment.boardsContentsGroupId = this.content.groupId;
    BoardUtil.generateHtml(comment);
    log(
      LogLevel.UI_ACTION,
      "ViewerBody:onComment start",
      comment,
      this.content
    );
    let result;
    if (this.props.open) {
      result = await fetchAPI(API.COMMENT_OPEN_NEW, "", null, comment);
    } else {
      result = await fetchAPI(
        API.COMMENT_NEW,
        "",
        null,
        comment,
        getGlobal(GlobalKey.TOKEN)
      );
    }
    if (result && !result.error) {
      comment.id = result.data;
      // AMPLITUDE
      let body;
      if (this.content.subject) body = this.content.subject.substring(0, 10);
      else if (this.content.bodyText)
        body = this.content.bodyText.substring(0, 10);
      let name = "";
      if (this.content.usersNickname) name = this.content.usersNickname;
      else this.content.usersCustomName;
      name = this.content.usersCustomName;
      AnalyticsUtil.event(
        AnalyticsUtil.TYPE_ALL,
        "CONTENT_COMMENT",
        "게시물_코멘트",
        {
          게시물id: this.content.id,
          게시물내용: body,
          글쓴이id: this.content.usersId,
          글쓴이닉네임: name,
          게시판이름: this.content.boardsCategoriesName,
        }
      );

      if (this.advertiserInfo) {
        AnalyticsUtil.event(
          AnalyticsUtil.TYPE_ALL,
          "ADVERTISE_REACTION",
          "광고고객반응",
          {
            type: "댓글",
            ...this.advertiserInfo,
            게시물내용: body,
          }
        );
      }

      if (this.props.postingId) this.props.loadContent(this.props.postingId);
      else if (this.props.onContentUpdate) {
        comment.createdAt = new Date(
          Date.now() - new Date().getTimezoneOffset() * 60000
        )
          .toISOString()
          .substring(0, 16);
        comment.usersId = -1;
        comment.likeCnt = 0;
        comment.dislikeCnt = 0;
        comment.id = result.data;
        let newContent = { ...this.content };
        newContent.comments = [...newContent.comments, comment];
        this.content = newContent;
        this.updateContent(BoardType.NONE);

        // this.props.onContentUpdate(newContent, this.props.replyIndex);
      }
    }
    this.props.checkAccomplished();
    log(LogLevel.UI_ACTION, "ViewerBody:onComment", result, this.content);
  };

  onCommentChange = async (comment: BoardComment, index: number) => {
    let result;
    BoardUtil.generateHtml(comment);
    if (this.props.open) {
      result = await fetchAPI(API.COMMENT_OPEN_UPDATE, "", null, comment);
    } else {
      result = await fetchAPI(
        API.COMMENT_UPDATE,
        "",
        null,
        comment,
        getGlobal(GlobalKey.TOKEN)
      );
    }
    if (result && !result.error) {
      if (this.props.postingId) this.props.loadContent(this.props.postingId);
      else if (this.props.onContentUpdate) {
        let newContent = { ...this.content };
        newContent.comments = [...newContent.comments];
        newContent.comments[index] = comment;
        this.content = newContent;
        this.updateContent(BoardType.NONE);

        // this.props.onContentUpdate(newContent, this.props.replyIndex);
      }
    }
  };

  onCommentDelete = async (
    comment: BoardComment,
    index: number,
    isAdmin: boolean
  ) => {
    let result;
    if (this.props.open) {
      result = await fetchAPI(
        API.COMMENT_OPEN_DELETE,
        "",
        { id: comment.id, password: comment.password },
        null
      );
    } else {
      result = await fetchAPI(
        API.COMMENT_DELETE,
        comment.id,
        { admin: isAdmin },
        null,
        getGlobal(GlobalKey.TOKEN)
      );
    }
    if (!result.error) {
      if (this.props.postingId) this.props.loadContent(this.props.postingId);
      else if (this.props.onContentUpdate) {
        let newContent = { ...this.content };
        newContent.comments = [...newContent.comments];
        newContent.comments.splice(index, 1);
        this.content = newContent;
        this.updateContent(BoardType.NONE);

        // this.props.onContentUpdate(newContent, this.props.replyIndex);
      }
    }
  };

  onCommentReport = async (comment: BoardComment, type: number) => {
    let result = await fetchAPI(
      API.COMMENT_ACTION_REPORT,
      type + "/" + comment.id,
      null,
      null,
      getGlobal(GlobalKey.TOKEN)
    );

    // ## TODO - 신고후처리
  };

  onCommentLike = async (comment: BoardComment, index: number) => {
    log(LogLevel.UI_ACTION, "ViewerBody:onCommentLike", comment.id, index);
    // AMPLITUDE
    let body;
    if (this.content.bodyText) body = this.content.bodyText.substring(0, 10);
    else if (comment.imageUrl) body = "image";
    else if (comment.fileName) body = "fileName";
    let parentBody;
    if (this.content.subject)
      parentBody = this.content.subject.substring(0, 10);
    else if (this.content.bodyText)
      parentBody = this.content.bodyText.substring(0, 10);
    let name = "";
    if (comment.usersNickname) name = this.content.usersNickname;
    if (comment.liked) {
      if (this.props.open)
        fetchAPI(API.OPEN_COMMENT_ACTION_CANCEL_LIKE, comment.id, null, null);
      else
        fetchAPI(
          API.COMMENT_ACTION_CANCEL_LIKE,
          comment.id,
          null,
          null,
          getGlobal(GlobalKey.TOKEN)
        );
      AnalyticsUtil.event(
        AnalyticsUtil.TYPE_ALL,
        "CONTENT_COMMENT_LIKE_CANCEL",
        "게시물_코멘트_좋아요_취소",
        {
          게시물id: this.content.id,
          게시물내용: parentBody,
          코멘트id: comment.id,
          코멘트내용: body,
          글쓴이id: comment.usersId,
          글쓴이닉네임: name,
          게시판이름: this.content.boardsCategoriesName,
        }
      );
    } else {
      if (this.props.open)
        fetchAPI(API.OPEN_COMMENT_ACTION_LIKE, comment.id, null, null);
      else
        fetchAPI(
          API.COMMENT_ACTION_LIKE,
          comment.id,
          null,
          null,
          getGlobal(GlobalKey.TOKEN)
        );
      AnalyticsUtil.event(
        AnalyticsUtil.TYPE_ALL,
        "CONTENT_COMMENT_LIKE",
        "게시물_코멘트_좋아요",
        {
          게시물id: this.content.id,
          게시물내용: parentBody,
          코멘트id: comment.id,
          코멘트내용: body,
          글쓴이id: comment.usersId,
          글쓴이닉네임: name,
          게시판이름: this.content.boardsCategoriesName,
        }
      );
      if (this.advertiserInfo) {
        AnalyticsUtil.event(
          AnalyticsUtil.TYPE_ALL,
          "ADVERTISE_REACTION",
          "광고고객반응",
          {
            type: "좋아요",
            ...this.advertiserInfo,
          }
        );
      }
    }
    if (this.props.postingId) this.props.loadContent(this.props.postingId);
    comment.likeCnt = comment.likeCnt + (comment.liked ? -1 : 1);
    comment.liked = !comment.liked;
    this.content.comments[index] = { ...comment };
    this.content.comments = [...this.content.comments];
    this.updateContent();
  };

  onCommentDislike = async (comment: BoardComment) => {
    let result;
    if (this.props.open) return;

    if (comment.disliked)
      result = await fetchAPI(
        API.COMMENT_ACTION_CANCEL_DISLIKE,
        comment.id,
        null,
        null,
        getGlobal(GlobalKey.TOKEN)
      );
    else {
      result = await fetchAPI(
        API.COMMENT_ACTION_DISLIKE,
        comment.id,
        null,
        null,
        getGlobal(GlobalKey.TOKEN)
      );
      // AMPLITUDE
      let body;
      if (this.content.bodyText) body = this.content.bodyText.substring(0, 10);
      else if (comment.imageUrl) body = "image";
      else if (comment.fileName) body = "fileName";
      let parentBody;
      if (this.content.subject)
        parentBody = this.content.subject.substring(0, 10);
      else if (this.content.bodyText)
        parentBody = this.content.bodyText.substring(0, 10);
      let name = "";
      if (comment.usersNickname) name = this.content.usersNickname;
      AnalyticsUtil.event(
        AnalyticsUtil.TYPE_ALL,
        "CONTENT_COMMENT_LIKE",
        "게시물_코멘트_싫어요",
        {
          게시물id: this.content.id,
          게시물내용: parentBody,
          코멘트id: comment.id,
          코멘트내용: body,
          글쓴이id: comment.usersId,
          글쓴이닉네임: name,
          게시판이름: this.content.boardsCategoriesName,
        }
      );
    }
    if (!result.error) {
      if (this.props.postingId) this.props.loadContent(this.props.postingId);
    }
  };

  onCommentReply = async (comment: BoardComment, index: number) => {
    if (this.props.open) {
      return;
    }
    comment.boardsContentsId = this.content.id;
    comment.boardsContentsGroupId = this.content.groupId;
    BoardUtil.generateHtml(comment);
    log(
      LogLevel.UI_ACTION,
      "ViewerBody:onCommentReply start",
      comment,
      this.content
    );
    let result = await fetchAPI(
      API.COMMENT_NEW,
      "",
      null,
      comment,
      getGlobal(GlobalKey.TOKEN)
    );
    if (result && !result.error) {
      comment.id = result.data;
      // AMPLITUDE
      let body;
      if (this.content.subject) body = this.content.subject.substring(0, 10);
      else if (this.content.bodyText)
        body = this.content.bodyText.substring(0, 10);
      let name = "";
      if (this.content.usersNickname) name = this.content.usersNickname;
      else this.content.usersCustomName;
      name = this.content.usersCustomName;
      AnalyticsUtil.event(
        AnalyticsUtil.TYPE_ALL,
        "CONTENT_COMMENT_REPLY",
        "게시물_코멘트_답변",
        {
          게시물id: this.content.id,
          게시물내용: body,
          글쓴이id: this.content.usersId,
          글쓴이닉네임: name,
          게시판이름: this.content.boardsCategoriesName,
        }
      );

      if (this.props.postingId) this.props.loadContent(this.props.postingId);
      else if (this.props.onContentUpdate) {
        comment.createdAt = new Date(
          Date.now() - new Date().getTimezoneOffset() * 60000
        )
          .toISOString()
          .substring(0, 16);
        comment.usersId = -1;
        comment.likeCnt = 0;
        comment.dislikeCnt = 0;
        comment.id = result.data;
        let newContent = { ...this.content };
        newContent.comments = [
          ...newContent.comments.slice(0, index),
          comment,
          ...newContent.comments.slice(index),
        ];
        this.content = newContent;
        this.updateContent(BoardType.NONE);

        // this.props.onContentUpdate(newContent, this.props.replyIndex);
      }
    }
  };

  onLike = async () => {
    if (this.content && this.content.deletedAt) return;

    let liked = this.content.liked;
    let likeCnt = this.content.likeCnt;
    log(LogLevel.UI_ACTION, "ViewerBody:onLike", !liked, this.content);
    let token = getGlobal(GlobalKey.TOKEN);
    let result;
    // AMPLITUDE
    let body;
    if (this.content.subject) body = this.content.subject.substring(0, 10);
    else if (this.content.bodyText)
      body = this.content.bodyText.substring(0, 10);
    let name = "";
    if (this.content.usersNickname) name = this.content.usersNickname;
    else if (this.content.usersCustomName) name = this.content.usersCustomName;

    // log(LogLevel.UI_ACTION, "ViewerBody:onLike name", name, this.content);
    if (liked) {
      if (this.props.open)
        result = fetchAPI(
          API.OPEN_ACTION_CANCEL_LIKE,
          this.content.id,
          null,
          null
        );
      else
        result = fetchAPI(
          API.ACTION_CANCEL_LIKE,
          this.content.id,
          null,
          null,
          token
        );
      likeCnt--;
      AnalyticsUtil.event(
        AnalyticsUtil.TYPE_ALL,
        "CONTENT_LIKE_CANCEL",
        "게시물_좋아요_취소",
        {
          게시물id: this.content.id,
          게시물내용: body,
          글쓴이id: this.content.usersId,
          글쓴이닉네임: name,
          게시판이름: this.content.boardsCategoriesName,
        }
      );
    } else {
      if (this.props.open)
        result = fetchAPI(API.OPEN_ACTION_LIKE, this.content.id, null, null);
      else
        result = fetchAPI(API.ACTION_LIKE, this.content.id, null, null, token);
      likeCnt++;
      // AMPLITUDE
      AnalyticsUtil.event(
        AnalyticsUtil.TYPE_ALL,
        "CONTENT_LIKE",
        "게시물_좋아요",
        {
          게시물id: this.content.id,
          게시물내용: body,
          글쓴이id: this.content.usersId,
          글쓴이닉네임: name,
          게시판이름: this.content.boardsCategoriesName,
        }
      );

      if (this.advertiserInfo) {
        AnalyticsUtil.event(
          AnalyticsUtil.TYPE_ALL,
          "ADVERTISE_REACTION",
          "광고고객반응",
          {
            type: "좋아요",
            ...this.advertiserInfo,
          }
        );
      }
    }
    log(LogLevel.UI_ACTION, "ViewerBody:onLike result", result);

    this.content.likeCnt = likeCnt;
    this.content.liked = !liked;
    this.updateContent(BoardType.MY_LIKE);

    if (ABTestUtil.isTest(ABTestFeature.NUDGE)) {
      if (
        this.content.liked &&
        !getGlobal(GlobalKey.NUDGE_READY) &&
        (!this.props.me.nudgeState ||
          this.props.me.nudgeState < UserNudgeState.NUDGED_IGNORED)
      ) {
        let me = this.props.me;
        me.likeCnt = me.likeCnt ? me.likeCnt + 1 : 1;
        this.props.setMe(me);
        let point: number =
          (this.props.me.likeCnt ? this.props.me.likeCnt : 0) +
          (this.props.me.scrapCnt ? this.props.me.scrapCnt : 0);
        if (
          UserNudgeStages[
            this.props.me.nudgeState ? this.props.me.nudgeState : 0
          ] < point
        ) {
          let today = Math.floor(
            (Date.now() + 9 * 60 * 60 * 1000) / (24 * 60 * 60 * 1000)
          );
          if (getGlobal(GlobalKey.NUDGED_LAST_DATE, true) < today) {
            setGlobal(GlobalKey.NUDGED_LAST_DATE, today, true);
            setGlobal(GlobalKey.NUDGE_READY, true);
          }
        }
      }
    }
  };

  onMetoo = async () => {
    if (this.content && this.content.deletedAt) return;

    let metoo = this.content.metoo;
    let meTooCnt = this.content.meTooCnt;
    log(LogLevel.UI_ACTION, "ViewerBody:onMetoo", !metoo);
    let token = getGlobal(GlobalKey.TOKEN);
    let result;
    if (metoo) {
      result = await fetchAPI(
        API.ACTION_CANCEL_METOO,
        this.content.id,
        null,
        null,
        token
      );
      meTooCnt--;
    } else {
      result = await fetchAPI(
        API.ACTION_METOO,
        this.content.id,
        null,
        null,
        token
      );
      meTooCnt++;
      // AMPLITUDE
      let body;
      if (this.content.subject) body = this.content.subject.substring(0, 10);
      else if (this.content.bodyText)
        body = this.content.bodyText.substring(0, 10);
      let name = "";
      if (this.content.usersNickname) name = this.content.usersNickname;
      else this.content.usersCustomName;
      name = this.content.usersCustomName;
      AnalyticsUtil.event(
        AnalyticsUtil.TYPE_ALL,
        "CONTENT_METOO",
        "게시물_나도궁금해요",
        {
          게시물id: this.content.id,
          게시물내용: body,
          글쓴이id: this.content.usersId,
          글쓴이닉네임: name,
        }
      );
    }
    if (result && !result.error) {
      this.content.meTooCnt = meTooCnt;
      this.content.metoo = !metoo;
      this.updateContent(BoardType.NONE);
    }
  };

  onDislike = async () => {
    if (this.content.deletedAt) return;

    let disliked = this.content.disliked;
    let dislikeCnt = this.content.dislikeCnt;
    log(LogLevel.NONE, "ViewerBody:doDislike", !disliked);
    let token = getGlobal(GlobalKey.TOKEN);
    let result;
    if (disliked) {
      result = await fetchAPI(
        API.ACTION_CANCEL_DISLIKE,
        this.content.id,
        null,
        null,
        token
      );
      dislikeCnt--;
    } else {
      result = await fetchAPI(
        API.ACTION_DISLIKE,
        this.content.id,
        null,
        null,
        token
      );
      dislikeCnt++;
      // AMPLITUDE
      let body;
      if (this.content.subject) body = this.content.subject.substring(0, 10);
      else if (this.content.bodyText)
        body = this.content.bodyText.substring(0, 10);
      let name = "";
      if (this.content.usersNickname) name = this.content.usersNickname;
      else this.content.usersCustomName;
      name = this.content.usersCustomName;
      AnalyticsUtil.event(
        AnalyticsUtil.TYPE_ALL,
        "CONTENT_DISLIKE",
        "게시물_싫어요",
        {
          게시물id: this.content.id,
          게시물내용: body,
          글쓴이id: this.content.usersId,
          글쓴이닉네임: name,
        }
      );
    }
    if (result && !result.error) {
      this.content.dislikeCnt = dislikeCnt;
      this.content.disliked = !disliked;
      this.updateContent(BoardType.NONE);
    }
  };

  showSingleImagePopup(image: string) {
    log(LogLevel.UI_ACTION, "ViewerBody:showSingleImagePopup", image);
    let os = getGlobal(GlobalKey.OS);
    if (
      os &&
      os != "browser" &&
      windowAny.PhotoViewer &&
      !(os == "ios" && image.startsWith("data:image/"))
    ) {
      try {
        windowAny.PhotoViewer.show(StringUtil.convertFilePath(image));
      } catch (e) {
        this.props.imageViewerPopup.setImages([
          StringUtil.convertFilePath(image),
        ]);
        this.props.imageViewerPopup.show(0);
      }
    } else {
      this.props.imageViewerPopup.setImages([
        StringUtil.convertFilePath(image),
      ]);
      this.props.imageViewerPopup.show(0);
    }
  }

  showImagePopup(index: number) {
    log(LogLevel.UI_ACTION, "ViewerBody:showImagePopup", index, this.content);
    let os = getGlobal(GlobalKey.OS);
    if (os && os != "browser" && windowAny.PhotoViewer) {
      try {
        windowAny.PhotoViewer.show(
          StringUtil.convertFilePath(this.content.images[index])
        );
      } catch (e) {
        log(LogLevel.UI_EXCEPTION, "ViewerBody:showImagePopup.failed");
        this.props.imageViewerPopup.setImages(
          this.content.images.map((image) => StringUtil.convertFilePath(image))
        );
        this.props.imageViewerPopup.show(index);
      }
    } else {
      this.props.imageViewerPopup.setImages(
        this.content.images.map((image) => StringUtil.convertFilePath(image))
      );
      this.props.imageViewerPopup.show(index);
    }
  }

  onPressKeyword = (type: number, id: number) => {
    log(LogLevel.UI_ACTION, "onPressKeyword", type, id);
    this.props.keywordPopup.show(type, id);
  };

  onPressAttachment = (index: number) => {
    log(LogLevel.UI_ACTION, "onPressAttachment", this.content.files[index]);
    let item = this.content.files[index];
    let os = getGlobal(GlobalKey.OS);
    // let path = encodeURI(item.url))
    // if(os == "android" && item.url.endsWith("pdf"))
    //   windowAny.open('https://docs.google.com/viewer?url='+ path + '&embedded=true', "_system");
    // else if(item.url.endsWith("ppt") || item.url.endsWith("pptx") || item.url.endsWith("doc") || item.url.endsWith("docx"))
    //   windowAny.open('https://docs.google.com/viewer?url='+ path + '&embedded=true', "_system");
    // else
    //   windowAny.open(path, "_system");
    if (os == "android" && item.url.endsWith("pdf"))
      windowAny.open(
        "https://docs.google.com/viewer?url=" +
          StringUtil.convertFilePath(item.url) +
          "&embedded=true",
        "_system"
      );
    else if (
      item.url.endsWith("ppt") ||
      item.url.endsWith("pptx") ||
      item.url.endsWith("doc") ||
      item.url.endsWith("docx")
    )
      windowAny.open(
        "https://docs.google.com/viewer?url=" +
          StringUtil.convertFilePath(item.url) +
          "&embedded=true",
        getGlobal(GlobalKey.OS) == "browser" ? "_blank" : "_system"
      );
    else if (
      os == "android" &&
      windowAny.cordova &&
      windowAny.cordova.plugins &&
      windowAny.cordova.plugins.DownloadManager &&
      windowAny.cordova.plugins.DownloadManager.download
    ) {
      this.props.toast.show("첨부파일 다운로드 중...");
      windowAny.cordova.plugins.DownloadManager.download(
        StringUtil.convertFilePath(item.url),
        item.name,
        item.name + "다운로드중",
        () => {
          this.props.toast.show("첨부파일 다운로드가 완료되었습니다.");
        },
        () => {
          this.props.toast.show("첨부파일 다운로드가 실패하였습니다.");
        }
      );
    } else
      windowAny.open(
        StringUtil.convertFilePath(item.url),
        getGlobal(GlobalKey.OS) == "browser" ? "_blank" : "_system"
      );

    // if(!os || os == "browser"){
    //   this.props.documentViewerPopup.show(this.content.files[index])
    // }else{
    //   let path:string;
    //   switch (os) {
    //     case "android":
    //       path = 'file:///storage/emulated/0/';
    //       break;
    //     case "ios":
    //       path = windowAny.cordova.file.documentsDirectory;
    //       break;
    //   }
    // }
  };

  onKakaoShare = () => {
    if (this.content && this.content.deletedAt) return;

    this.props.confirmPopup.show({
      title: (
        <div className="common-container common-color-highlight">잠깐만요,</div>
      ),
      body: (
        <div className="common-container">
          1. 약문약답의 모든 컨텐츠는 약사만을 위한 컨텐츠입니다. 비약사에게
          공유하시면 안되요~
          <br />
          <br />
          2. 공유된 링크는 약문약답앱에서만 확인 가능합니다.
        </div>
      ),
      iconImage: null,
      doneText: "공유하기",
      cancelText: "공유취소",
      onDone: this.doKakaoShare,
    });
  };

  doKakaoShare = async () => {
    log(LogLevel.UI_ACTION, "ViewerBody: doKakaoShare");

    let webUrl;
    let result = await fetchAPI(
      API.ACTION_SHARE,
      this.content.id,
      null,
      null,
      getGlobal(GlobalKey.TOKEN)
    );
    if (!!ABTestUtil.isTest(ABTestFeature.DISABLE_OPEN_PAGE_SHARE)) {
      if (result && !result.error) {
        webUrl = MY_ADDRESS + "/open?id=" + result.data;
      }
    }

    this.content.sharedCnt = this.content.sharedCnt + 1;
    this.content.shared = true;
    this.updateContent(BoardType.NONE);
    let os = getGlobal(GlobalKey.OS);

    let image;
    if (
      this.content.cards &&
      this.content.cards.length &&
      this.content.cards[0].url
    )
      image = StringUtil.convertFilePath(this.content.cards[0].url);
    else if (this.content.image)
      image = StringUtil.convertFilePath(this.content.image);
    else image = MY_ADDRESS + "/images/ymydgi_20220719.jpg";

    if (!ABTestUtil.isTest(ABTestFeature.DISABLE_OPEN_PAGE_SHARE) && webUrl) {
      if (!os || os == "browser") {
        let feed = {
          objectType: "feed",
          content: {
            title: this.content.subject,
            description: this.content.bodyText,
            imageUrl: image,
            link: {
              mobileWebUrl: webUrl,
              webUrl: webUrl,
              androidExecParams: "type=view&id=" + this.content.id,
              iosExecParams: "type=view&id=" + this.content.id,
            },
          },
          social: {
            likeCount: this.content.likeCntAll,
            commentCount: this.content.replyCnt,
            viewCount: this.content.viewCnt,
          },
          buttons: [
            {
              title: "Web에서 보기",
              link: {
                mobileWebUrl: webUrl,
                webUrl: webUrl,
              },
            },
            {
              title: "App에서 보기",
              link: {
                androidExecParams: "type=view&id=" + this.content.id,
                iosExecParams: "type=view&id=" + this.content.id,
              },
            },
          ],
        };
        //console.log("ViewerBody: doKakaoShare", feed)
        try {
          windowAny.Kakao.init("0d4139a6dc131b05b8109f629d7cc3f7");
        } catch (e) {
          log(LogLevel.UI_EXCEPTION, e);
        }
        windowAny.Kakao.Link.sendDefault(feed);
      } else if (windowAny.KakaoCordovaSDK) {
        let feed = {
          objectType: "feed",
          content: {
            title: this.content.subject,
            desc: this.content.bodyText,
            imageURL: image,
            link: {
              mobileWebURL: webUrl,
              webURL: webUrl,
              androidExecutionParams: "type=view&id=" + this.content.id,
              iosExecutionParams: "type=view&id=" + this.content.id,
            },
          },
          social: {
            likeCount: this.content.likeCntAll,
            commentCount: this.content.replyCnt,
            viewCount: this.content.viewCnt,
          },
          buttons: [
            {
              title: "Web에서 보기",
              link: {
                mobileWebURL: webUrl,
                webURL: webUrl,
              },
            },
            {
              title: "App에서 보기",
              link: {
                androidExecutionParams: "type=view&id=" + this.content.id,
                iosExecutionParams: "type=view&id=" + this.content.id,
              },
            },
          ],
        };
        windowAny.KakaoCordovaSDK.sendLinkFeed(
          feed,
          (res) => {
            console.log(
              "ViewerBody: doKakaoShare: Kakao share success",
              res,
              feed
            );
          },
          (res) => {
            console.log(
              "ViewerBody: doKakaoShare: Kakao share fail",
              res,
              feed
            );
          }
        );
      }
    } else {
      if (!os || os == "browser") {
        let feed = {
          objectType: "feed",
          content: {
            title: this.content.subject,
            description: this.content.bodyText,
            imageUrl: image,
            link: {
              androidExecParams: "type=view&id=" + this.content.id,
              iosExecParams: "type=view&id=" + this.content.id,
            },
          },
          social: {
            likeCount: this.content.likeCnt,
            commentCount: this.content.replyCnt,
            viewCount: this.content.viewCnt,
          },
          buttons: [
            {
              title: "App에서 보기",
              link: {
                androidExecParams: "type=view&id=" + this.content.id,
                iosExecParams: "type=view&id=" + this.content.id,
              },
            },
          ],
        };
        //console.log("ViewerBody: doKakaoShare", feed)
        try {
          windowAny.Kakao.init("0d4139a6dc131b05b8109f629d7cc3f7");
        } catch (e) {
          log(LogLevel.UI_EXCEPTION, e);
        }
        windowAny.Kakao.Link.sendDefault(feed);
      } else if (windowAny.KakaoCordovaSDK) {
        let feed = {
          objectType: "feed",
          content: {
            title: this.content.subject,
            desc: this.content.bodyText,
            imageURL: image,
            link: {
              androidExecutionParams: "type=view&id=" + this.content.id,
              iosExecutionParams: "type=view&id=" + this.content.id,
            },
          },
          social: {
            likeCount: this.content.likeCnt,
            commentCount: this.content.replyCnt,
            viewCount: this.content.viewCnt,
          },
          buttons: [
            {
              title: "App에서 보기",
              link: {
                androidExecutionParams: "type=view&id=" + this.content.id,
                iosExecutionParams: "type=view&id=" + this.content.id,
              },
            },
          ],
        };
        windowAny.KakaoCordovaSDK.sendLinkFeed(
          feed,
          (res) => {
            console.log(
              "ViewerBody: doKakaoShare: Kakao share success",
              res,
              feed
            );
          },
          (res) => {
            console.log(
              "ViewerBody: doKakaoShare: Kakao share fail",
              res,
              feed
            );
          }
        );
      }
    }

    // AMPLITUDE
    let body;
    if (this.content.subject) body = this.content.subject.substring(0, 10);
    else if (this.content.bodyText)
      body = this.content.bodyText.substring(0, 10);
    let name = "";
    if (this.content.usersNickname) name = this.content.usersNickname;
    else this.content.usersCustomName;
    name = this.content.usersCustomName;
    AnalyticsUtil.event(
      AnalyticsUtil.TYPE_ALL,
      "CONTENT_SHARE",
      "게시물_공유",
      {
        게시물id: this.content.id,
        게시물내용: body,
        글쓴이id: this.content.usersId,
        글쓴이닉네임: name,
        게시판이름: this.content.boardsCategoriesName,
      }
    );
  };

  onClickLink = (url: string) => {
    log(
      LogLevel.UI_ACTION,
      "onClickLink",
      url,
      url.substring(MY_ADDRESS.length),
      this.content
    );
    if (this.props.noNavigate) return;

    if (url.startsWith("&&&")) {
      if (getGlobal(GlobalKey.OS) != "browser") {
        this.props.history.push(url.substring(3));
      } else {
        window.open(
          url.replace("&&&", MY_ADDRESS),
          getGlobal(GlobalKey.OS) == "browser" ? "_blank" : "_system"
        );
      }
    } else if (url.startsWith(MY_ADDRESS)) {
      if (getGlobal(GlobalKey.OS) != "browser") {
        this.props.history.push(url);
      } else {
        window.open(
          url,
          getGlobal(GlobalKey.OS) == "browser" ? "_blank" : "_system"
        );
      }
    } else if (url.startsWith("/")) {
      this.props.history.push(url);
    } else if (url.startsWith("tel") || url.startsWith("sms")) {
      window.open(url, "_system");
    } else
      window.open(
        url,
        getGlobal(GlobalKey.OS) == "browser" ? "_blank" : "_system"
      );

    // AMPLITUDE
    if (this.content) {
      let body;
      if (this.content.subject) body = this.content.subject.substring(0, 10);
      else if (this.content.bodyText)
        body = this.content.bodyText.substring(0, 10);
      let name = "";
      if (this.content.usersNickname) name = this.content.usersNickname;
      else this.content.usersCustomName;
      name = this.content.usersCustomName;
      AnalyticsUtil.event(
        AnalyticsUtil.TYPE_ALL,
        "CONTENT_CLINK_LINK",
        "게시물_링크_클릭",
        {
          게시물id: this.content.id,
          게시물내용: body,
          글쓴이id: this.content.usersBizId
            ? this.content.usersBizId
            : this.content.usersId,
          글쓴이닉네임: name,
          게시판이름: this.content.boardsCategoriesName,
          link: url,
        }
      );
    }
  };

  onClickCard = (item, index, position) => {
    let url = "";

    if (item.link) url = item.link;
    else if (item.partialLinks && item.partialLinks.length) {
      for (let i = 0; i < item.partialLinks.length; i++) {
        if (
          position.x >= item.partialLinks[i].left &&
          position.x <= item.partialLinks[i].right &&
          position.y >= item.partialLinks[i].top &&
          position.y <= item.partialLinks[i].bottom
        ) {
          url = item.partialLinks[i].link;
          break;
        }
      }
    }

    if (url) {
      this.onClickLink(url);
      let body;
      if (this.content.subject) body = this.content.subject.substring(0, 10);
      else if (this.content.bodyText)
        body = this.content.bodyText.substring(0, 10);

      AnalyticsUtil.event(
        AnalyticsUtil.TYPE_ALL,
        "CONTENT_LIKE",
        "게시물_카드뉴스_링크",
        {
          게시물id: this.content.id,
          게시물내용: body,
          링크: item.link,
        }
      );

      if (this.advertiserInfo) {
        AnalyticsUtil.event(
          AnalyticsUtil.TYPE_ALL,
          "ADVERTISE_REACTION",
          "광고고객반응",
          {
            type: "링크클릭",
            ...this.advertiserInfo,
          }
        );
      }
    }
  };

  transformLastMention = false;
  transform = (node, index) => {
    log(LogLevel.UI_DATA_LOAD, "transform", node);

    let children: any = "";
    if (node.children && node.children.length) {
      children = node.children.map((childNode: any, childIndex: number) => {
        return React.createElement(
          React.Fragment,
          { key: childIndex },
          this.transform(childNode, childIndex)
        );
      });
    }

    if (node.type == "text") {
      let str = node.data;
      if (this.transformLastMention && str.startsWith(" ")) {
        str = str.substring(1);
        this.transformLastMention = false;
      }
      let paragraphs = str.split("\n");
      log(LogLevel.UI_DATA_LOAD, "transform.paragraphs", paragraphs);
      let rvalue = paragraphs[0];
      for (let i = 1; i < paragraphs.length; i++) {
        rvalue += <br />;
        rvalue += paragraphs[i];
      }
      return rvalue;
    } else if (node.type == "tag" && node.name == "br") {
      this.transformLastMention = false;
      return <br key={`${index}`} />;
    } else if (node.type == "tag" && node.name == "u") {
      log(
        LogLevel.UI_DATA_LOAD,
        "transform.u",
        children,
        node,
        node.attribs.style
      );
      return (
        <u style={{ ...styleObject(node.attribs ? node.attribs.style : null) }}>
          {children}
        </u>
      );
    } else if (node.type == "tag" && node.name == "em") {
      log(
        LogLevel.UI_DATA_LOAD,
        "transform.em",
        children,
        node,
        node.attribs.style
      );
      return (
        <em
          style={{ ...styleObject(node.attribs ? node.attribs.style : null) }}
        >
          {children}
        </em>
      );
    } else if (node.type == "tag" && node.name == "s") {
      log(
        LogLevel.UI_DATA_LOAD,
        "transform.s",
        children,
        node,
        node.attribs.style
      );
      return (
        <s style={{ ...styleObject(node.attribs ? node.attribs.style : null) }}>
          {children}
        </s>
      );
    } else if (node.type == "tag" && node.name == "b") {
      log(
        LogLevel.UI_DATA_LOAD,
        "transform.b",
        children,
        node,
        node.attribs.style
      );
      return (
        <b style={{ ...styleObject(node.attribs ? node.attribs.style : null) }}>
          {children}
        </b>
      );
    } else if (node.type == "tag" && node.name == "strong") {
      log(
        LogLevel.UI_DATA_LOAD,
        "transform.strong",
        children,
        node.attribs.style
      );
      return (
        <strong
          style={{ ...styleObject(node.attribs ? node.attribs.style : null) }}
        >
          {children}
        </strong>
      );
    } else if (
      node.type == "tag" &&
      node.name == "span" &&
      node.attribs.class &&
      node.attribs.class == "keywords"
    ) {
      log(LogLevel.UI_DATA_LOAD, "transform.keyword", node, this, this.props);
      if (
        node.attribs.keywordtype == KeywordType.MEDICINE ||
        node.attribs.keywordtype == KeywordType.INGRADIENT
      ) {
        this.props.fetchKeyword(
          parseInt(node.attribs.keywordtype),
          parseInt(node.attribs.keywordid)
        );
        let text = node.children[0].data;
        return (
          <span
            key={`${index}`}
            className="viewerbody-keywords"
            onClick={() =>
              this.onPressKeyword(
                node.attribs.keywordtype,
                node.attribs.keywordid
              )
            }
          >
            {node.children[0].data}
            <img className="viewerbody-keywords-icon" src={drugIcon} />
          </span>
        );
      }
      return node.children[0].data;
    } else if (
      node.type == "tag" &&
      node.name == "span" &&
      node.attribs.class &&
      node.attribs.class == "components"
    ) {
      log(
        LogLevel.UI_DATA_LOAD,
        "transform.components",
        node,
        this,
        this.props,
        node.attribs
      );
      return ComponentGenerator.generate(node.attribs);
    } else if (
      node.type == "tag" &&
      node.name == "span" &&
      node.attribs.class &&
      node.attribs.class == "mention"
    ) {
      log(
        LogLevel.UI_DATA_LOAD,
        "transform.keyword mention",
        node,
        this,
        this.props
      );
      let text = "";
      if (
        node.attribs["data-type"] == KeywordType.MEDICINE ||
        node.attribs["data-type"] == KeywordType.INGRADIENT ||
        node.attribs["data-type"] == KeywordType.ANIMAL_MEDICINE
      ) {
        // this.props.fetchKeyword(parseInt(node.attribs["data-type"]), parseInt(node.attribs["data-id"]));
        if (node.children.length > 1 && node.children[1].name == "span") {
          if (node.children[1].children) {
            for (let i = 0; i < node.children[1].children.length; i++) {
              if (node.children[1].children[i].type == "text") {
                text = node.children[1].children[i].data;
                // log(LogLevel.UI_DATA_LOAD, "transform.keyword mention 2", node.children[1].children[i], text );
                break;
              }
            }
          }
        }
        this.transformLastMention = true;
        return (
          <span
            key={`${index}`}
            className="viewerbody-keywords"
            onClick={() =>
              this.onPressKeyword(
                node.attribs["data-type"],
                node.attribs["data-id"]
              )
            }
          >
            {text}
            <img className="viewerbody-keywords-icon" src={drugIcon} />
          </span>
        );
      } else {
        if (node.children.length > 1 && node.children[1].name == "span") {
          if (node.children[1].children) {
            for (let i = 0; i < node.children[1].children.length; i++) {
              if (node.children[1].children[i].type == "text") {
                this.transformLastMention = true;
                return node.children[1].children[i].data;
              }
            }
          }
        }
      }
      return "";
    } else if (node.type == "tag" && node.name == "j") {
      let text = node.children[0].data;
      return (
        <span
          key={index.toString()}
          className="common-color-highlight"
          onClick={() => {
            const time = extractTimeValue(text);

            if (this.videoEl) {
              this.videoEl.jumpTo(time);
              if (!this.videoEl.isPlaying()) {
                this.videoEl.play();
              }
            }
          }}
        >
          {text}
        </span>
      );
    } else if (node.type == "tag" && node.name == "img") {
      log(LogLevel.UI_DATA_LOAD, "transform.img", node, this, this.props);
      // this.props.fetchKeyword(parseInt(node.attribs.keywordtype), parseInt(node.attribs.keywordid));
      let attrib: any = {};
      if (node.attribs.src) {
        attrib.src = StringUtil.convertFilePath(node.attribs.src);
        return (
          <img
            style={{ borderRadius: "8px" }}
            key={`${index}`}
            {...attrib}
            onClick={() => {
              if (!node.noImageShow)
                this.showSingleImagePopup(node.attribs.src);
            }}
          />
        );
      }
      return "";
    } else if (node.type == "tag" && node.name == "a") {
      let url = node.attribs.href;

      log(
        LogLevel.UI_DATA_LOAD,
        "ViewerBody:transform: a tag",
        children,
        url,
        node
      );

      if (!children || children == url) {
        if (url.startsWith("&&&")) url = url.replace("&&&", MY_ADDRESS);

        if (
          node.attribs.description ||
          node.attribs.title ||
          node.attribs.images
        ) {
          log(LogLevel.UI_DATA_LOAD, "ViewerBody:transform: a tag", node);
          return (
            <div
              className="viewerbody-links-container"
              key={`${index}`}
              onClick={() => this.onClickLink(node.attribs.href)}
            >
              {/* <div className="viewerbody-links">
                {url}
              </div> */}
              <LinkMeta
                link={node.attribs.href}
                title={node.attribs.title}
                description={node.attribs.description}
                image={node.attribs.images}
                onClick={this.onClickLink}
              />
            </div>
          );
        } else if (node.attribs.href && this.props.links[node.attribs.href]) {
          let reference: Reference = this.props.links[node.attribs.href];
          log(LogLevel.UI_DATA_LOAD, "ViewerBody:transform: a tag", reference);
          if (reference.description || reference.title || reference.image) {
            return (
              <div
                className="viewerbody-links-container"
                key={`${index}`}
                onClick={() => this.onClickLink(reference.url)}
              >
                {/* <div className="viewerbody-links">
                  {url}
                </div> */}
                <LinkMeta
                  link={reference.url}
                  title={reference.title}
                  description={reference.description}
                  image={reference.image}
                  onClick={this.onClickLink}
                />
              </div>
            );
          } else {
            return (
              <span
                className="viewerbody-links-container"
                key={`${index}`}
                onClick={() => this.onClickLink(reference.url)}
                style={{ ...styleObject(node.attribs.style) }}
              >
                {children ? children : url}
              </span>
            );
          }
        } else {
          this.props.loadLink(node.attribs.href, this.content.id);
          return (
            <span
              key={`${index}`}
              className="viewerbody-links-container"
              onClick={() => this.onClickLink(node.attribs.href)}
              style={{ ...styleObject(node.attribs.style) }}
            >
              {children ? children : url}
            </span>
          );
        }
      } else {
        return (
          <span
            key={`${index}`}
            className="viewerbody-links-container"
            onClick={() => this.onClickLink(node.attribs.href)}
            style={{ ...styleObject(node.attribs.style) }}
          >
            {children ? children : url}
          </span>
        );
      }
    }

    log(LogLevel.UI_EXCEPTION, "ViewerBody:transform: unknown", node);
    return undefined;
  };

  // bodySummary = (contents: BoardContent) => {
  //   if (
  //     !contents.bodySummary ||
  //     !contents.bodySummary.replies ||
  //     contents.bodySummary.replies.length == 0
  //   ) {
  //     return <></>;
  //   }

  //   return contents.bodySummary;
  // };
  // repliesSummary = (contents: BoardContent) => {
  //   if (
  //     !contents.bodySummary ||
  //     !contents.bodySummary.replies ||
  //     contents.bodySummary.replies.length == 0
  //   ) {
  //     return null;
  //   }

  //   console.log("hello", contents.bodySummary);

  //   return contents.bodySummary.replies;
  // };

  renderTitle() {
    let posting = this.content;
    let rvalue;

    if (
      !posting ||
      !(
        !posting.groupParent &&
        posting.boardsCategoriesAttribute.includes(BoardAttribute.HAS_TITLE)
      )
    )
      return rvalue;

    let qustionMart;
    let title = posting.subject;

    if (!posting.groupParent) {
      if (posting.isNice) {
        qustionMart = (
          <img className="viewerbody-title-nice-icon" src={badgeIcon} />
        );
      } else if (
        posting.boardsCategoriesAttribute.includes(BoardAttribute.IS_QNA)
      ) {
        qustionMart = (
          <img className="viewerbody-title-icon" src={qustionIcon} />
        );
      }

      if (
        posting.boardsCategoriesAttribute.includes(BoardAttribute.HAS_TITLE) &&
        !posting.subject
      )
        title = "제목없음";
    }

    rvalue = (
      <Static
        customStyle={{
          userSelect: "text !important",
          WebkitUserSelect: "text !important",
          marginTop: this.props.isOnlyQuestion ? "0" : "24px",
        }}
      >
        <Text textType="H2" color={COLOR_SYSTEM.get("Gray")[900]}>
          {ReactHtmlParser(title.replace(/\n/gi, "<br/>"), {})}
        </Text>
      </Static>
    );
    return rvalue;
  }

  renderKeywords() {
    let posting = this.content;

    if (!posting.keywords || posting.keywords.length == 0) {
      log(LogLevel.NONE, "no keywords");
      return null;
    }
    log(LogLevel.UI_LIFECYCLE, "ViewerBody:renderKeywords", posting.keywords);

    return (
      <Flex alignItems="center" gap="8px" customStyle={{ margin: "40px 0" }}>
        {posting.keywords.map((keyword, index) => (
          <Tag variant="Tinted" color="Blue" key={index}>
            {keyword.tagName ? keyword.tagName : keyword.text}
          </Tag>
        ))}
      </Flex>
    );
  }

  renderImages() {
    let posting = this.content;

    if (!posting.images || posting.images.length == 0) {
      log(LogLevel.NONE, "no images");
      return null;
    }

    return (
      <div className="viewerbody-images-container">
        {posting.images.map((item, index: number) => (
          <img
            className="viewerbody-image"
            src={StringUtil.convertFilePath(item)}
            key={index.toString()}
            onClick={() => this.showImagePopup(index)}
          />
        ))}
      </div>
    );
  }

  renderFiles() {
    let posting = this.content;

    if (!posting.files || posting.files.length == 0) {
      log(LogLevel.NONE, "no files");
      return null;
    }

    return (
      <Static
        customStyle={{
          "& > * + *": {
            marginTop: "12px",
          },
        }}
      >
        {posting.files.map((file, i) => {
          return (
            <Attachment
              key={i.toString()}
              index={i}
              url={StringUtil.convertFilePath(file.url)}
              name={file.name}
              size={file.size}
              onPress={this.onPressAttachment}
            />
          );
        })}
      </Static>
    );
  }

  render() {
    let posting = this.content;

    if (posting == null) return null;

    let isAdmin: boolean =
      this.props.me && this.props.me.level >= UserLevel.MANAGER;
    log(
      LogLevel.UI_LIFECYCLE,
      "ViewerBody.render",
      posting,
      this.content.boardsCategoriesAttribute,
      this.props,
      isAdmin,
      this.anonymousName
    );

    let isUpdatedFromLastView = false;
    let lastViewedAt;
    if (
      this.props.replyIndex >= 0 &&
      this.props.replyIndex >= 0 &&
      ABTestUtil.isTest(ABTestFeature.UI_VIEW_UPDATED) &&
      !posting.editable &&
      this.props.contents[this.props.postingId]
    ) {
      lastViewedAt = this.props.contents[this.props.postingId].lastViewedAt;
      if (
        lastViewedAt &&
        this.props.replyIndex >= 0 &&
        lastViewedAt < posting.createdAt
      ) {
        isUpdatedFromLastView = true;
      }
    }

    let html = posting.bodyHtml;
    if (!html) html = BoardUtil.generateHtml(posting).bodyHtml;
    if (html.startsWith("<div>")) {
      let lastindex = html.length - 1 - 5;
      html = html.substring(5, lastindex);
      log(LogLevel.NONE, posting.bodyHtml, html, lastindex);
    }
    if (posting.videoUrl) html = extractTimeToJTag(html);

    // if (!this.content.groupParent) popoverMenu.push(this.menuClipboard);

    let toolbar;
    let toolbarItemList = [];
    let index = 0;
    if (
      this.props &&
      !this.props.noToolbar &&
      posting.boardsCategoriesAttribute &&
      ((!posting.groupParent &&
        posting.boardsCategoriesAttribute.includes(
          BoardAttribute.UI_VIEWER_TOOLBAR_ON_POST
        )) ||
        (posting.groupParent &&
          posting.boardsCategoriesAttribute.includes(
            BoardAttribute.UI_VIEWER_TOOLBAR_ON_REPLY
          )))
    ) {
      toolbarItemList.push(
        <div key={(index++).toString()} className="common-flex-grow-2" />
      );
      if (!posting.groupParent) {
        // Post toolbar
        if (
          posting.boardsCategoriesAttribute.includes(
            BoardAttribute.CAN_LIKE_ON_POST
          )
        ) {
          let emphasisLike = false;
          if (
            posting.boardsCategoriesAttribute.includes(
              BoardAttribute.UI_VIEWER_LIKE_DISLIKE_EMPHASIS
            ) &&
            posting.likeCnt &&
            (!posting.dislikeCnt || posting.likeCnt > posting.dislikeCnt)
          )
            emphasisLike = true;
          let title = "좋아요";
          if (
            posting.boardsCategoriesAttribute.includes(
              BoardAttribute.IS_DISCUSSION
            )
          )
            title = "찬성";
          toolbarItemList.push(
            // <ActionButton
            //   key={(index++).toString()}
            //   open={this.props.open}
            //   onClick={this.onLike}
            //   title={title}
            //   src={likeIcon}
            //   value={this.content.liked}
            //   label={this.content.likeCnt}
            //   emphasis={emphasisLike}
            // />
            <Static
              key={(index++).toString()}
              customStyle={{ margin: "40px 0 20px" }}
            >
              <ToggleButton
                color="Skyblue"
                size="Medium"
                variant="Tinted"
                type="IconWithText"
                active={this.content.liked}
                left
                icon="Thumbs Up Filled"
                onClick={this.onLike}
              >
                좋아요 {this.content.likeCnt > 0 ? this.content.likeCnt : ""}
              </ToggleButton>
            </Static>
          );
          // toolbarItemList.push(<div key={(index++).toString()} className="common-flex-grow-2" />);
        }
        if (
          posting.boardsCategoriesAttribute.includes(
            BoardAttribute.CAN_LIKE_ON_POST
          ) &&
          posting.boardsCategoriesAttribute.includes(BoardAttribute.CAN_DISLIKE)
        ) {
          let emphasisDislike = false;
          if (
            posting.boardsCategoriesAttribute.includes(
              BoardAttribute.UI_VIEWER_LIKE_DISLIKE_EMPHASIS
            ) &&
            posting.dislikeCnt &&
            (!posting.likeCnt || posting.likeCnt < posting.dislikeCnt)
          )
            emphasisDislike = true;
          let title = "싫어요";
          if (
            posting.boardsCategoriesAttribute.includes(
              BoardAttribute.IS_DISCUSSION
            )
          )
            title = "반대";
          toolbarItemList.push(
            <ActionButton
              key={(index++).toString()}
              open={this.props.open}
              onClick={this.onDislike}
              title={title}
              src={dislikeIcon}
              value={this.content.disliked}
              label={this.content.dislikeCnt}
              emphasis={emphasisDislike}
            />
          );
          toolbarItemList.push(
            <div key={(index++).toString()} className="common-flex-grow-2" />
          );
        }
        if (
          this.content.boardsCategoriesAttribute.includes(
            BoardAttribute.CAN_SCRAP
          )
        ) {
          // toolbarItemList.push(
          //   <ActionButton
          //     key={(index++).toString()}
          //     open={this.props.open}
          //     onClick={this.onScrap}
          //     title="스크랩"
          //     src={scrapIcon}
          //     value={this.content.scrapped}
          //     label={this.content.scrapCnt}
          //   />
          // );
          // toolbarItemList.push(<div key={(index++).toString()} className="common-flex-grow-2" />);
        }
        if (
          this.content.boardsCategoriesAttribute.includes(
            BoardAttribute.CAN_METOO
          )
        ) {
          toolbarItemList.push(
            // <ActionButton
            //   key={(index++).toString()}
            //   open={this.props.open}
            //   onClick={this.onMetoo}
            //   title="답변알림받기"
            //   iconName="help"
            //   iconMode="md"
            //   value={this.content.metoo}
            //   label={this.content.meTooCnt}
            // />
            <Static
              key={(index++).toString()}
              customStyle={{ marginTop: "40px", transform: "translateZ(0)" }}
            >
              <ToggleButton
                color="Skyblue"
                size="Medium"
                variant="Tinted"
                type="IconWithText"
                left
                icon="Bell Ringing"
                active={this.content.metoo}
                onClick={this.onMetoo}
              >
                의견 알림받기{" "}
                {this.content.meTooCnt > 0 ? this.content.meTooCnt : ""}
              </ToggleButton>
            </Static>
          );
          // toolbarItemList.push(<div key={(index++).toString()} className="common-flex-grow-2" />);
        }
        // if (this.content.boardsCategoriesAttribute.includes(BoardAttribute.CAN_SHARE)) {
        // toolbarItemList.push(
        //   <ActionButton
        //     key={(index++).toString()}
        //     open={this.props.open}
        //     onClick={this.onKakaoShare}
        //     title="카톡공유"
        //     src={kakaoIcon}
        //     value={this.content.shared}
        //     label={this.content.sharedCnt}
        //   />
        // );
        // toolbarItemList.push(<div key={(index++).toString()} className="common-flex-grow-2" />);
        // }
      } else {
        if (
          posting.boardsCategoriesAttribute.includes(
            BoardAttribute.CAN_LIKE_ON_REPLY
          )
        ) {
          let emphasis = false;
          if (
            posting.boardsCategoriesAttribute.includes(
              BoardAttribute.UI_VIEWER_LIKE_DISLIKE_EMPHASIS
            ) &&
            posting.likeCnt &&
            (!posting.dislikeCnt || posting.likeCnt > posting.dislikeCnt)
          )
            emphasis = true;
          if (
            posting.boardsCategoriesAttribute.includes(
              BoardAttribute.IS_DISCUSSION
            )
          ) {
            toolbarItemList.push(
              // <ActionButton
              //   key={(index++).toString()}
              //   open={this.props.open}
              //   onClick={this.onLike}
              //   title="찬성"
              //   value={this.content.liked}
              //   label={this.content.likeCnt}
              //   emphasis={emphasis}
              // />
              <Static
                key={(index++).toString()}
                customStyle={{ margin: "40px 0 20px" }}
              >
                <ToggleButton
                  color="Skyblue"
                  size="Medium"
                  variant="Tinted"
                  type="IconWithText"
                  active={this.content.liked}
                  left
                  icon="Thumbs Up Filled"
                  onClick={this.onLike}
                >
                  좋아요 {this.content.likeCnt > 0 ? this.content.likeCnt : ""}
                </ToggleButton>
              </Static>
            );
            // toolbarItemList.push(<div key={(index++).toString()} className="common-flex-grow-2" />);
          } else {
            toolbarItemList.push(
              // <ActionButton
              //   key={(index++).toString()}
              //   open={this.props.open}
              //   onClick={this.onLike}
              //   src={likeIcon}
              //   value={this.content.liked}
              //   label={this.content.likeCnt}
              //   emphasis={emphasis}
              // />
              <Static
                key={(index++).toString()}
                customStyle={{ margin: "40px 0 20px" }}
              >
                <ToggleButton
                  color="Skyblue"
                  size="Medium"
                  variant="Tinted"
                  type="IconWithText"
                  active={this.content.liked}
                  left
                  icon="Thumbs Up Filled"
                  onClick={this.onLike}
                >
                  좋아요 {this.content.likeCnt > 0 ? this.content.likeCnt : ""}
                </ToggleButton>
              </Static>
            );
            // toolbarItemList.push(<div key={(index++).toString()} className="common-flex-grow-2" />);
          }
        }
        if (
          posting.boardsCategoriesAttribute.includes(
            BoardAttribute.CAN_LIKE_ON_REPLY
          ) &&
          posting.boardsCategoriesAttribute.includes(BoardAttribute.CAN_DISLIKE)
        ) {
          let emphasis = false;
          if (
            posting.boardsCategoriesAttribute.includes(
              BoardAttribute.UI_VIEWER_LIKE_DISLIKE_EMPHASIS
            ) &&
            posting.dislikeCnt &&
            (!posting.likeCnt || posting.likeCnt < posting.dislikeCnt)
          )
            emphasis = true;
          // if (posting.boardsCategoriesAttribute.includes(BoardAttribute.IS_DISCUSSION)) {
          // toolbarItemList.push(
          //   <ActionButton
          //     key={(index++).toString()}
          //     open={this.props.open}
          //     onClick={this.onDislike}
          //     title="반대"
          //     value={this.content.disliked}
          //     label={this.content.dislikeCnt}
          //     emphasis={emphasis}
          //   />
          // );
          // toolbarItemList.push(<div key={(index++).toString()} className="common-flex-grow-2" />);
          // } else {
          // toolbarItemList.push(
          //   <ActionButton
          //     key={(index++).toString()}
          //     open={this.props.open}
          //     onClick={this.onDislike}
          //     src={dislikeIcon}
          //     value={this.content.disliked}
          //     label={this.content.dislikeCnt}
          //     emphasis={emphasis}
          //   />
          // );
          // toolbarItemList.push(<div key={(index++).toString()} className="common-flex-grow-2" />);
          // }
        }
      }
      toolbar = <div>{toolbarItemList}</div>;
    } else {
      log(LogLevel.UI_LIFECYCLE, "ViewerBody.render: no toolbar", posting);
    }

    let flag,
      profileMarginClass = "";
    if (this.props.replyIndex >= 0) {
      flag = (
        <div className="viewerbody-reply-flag-container">
          <div className="viewerbody-reply-flag" />
          <div className="viewerbody-reply-name">
            의견 {this.props.replyIndex + 1}
          </div>
        </div>
      );
      profileMarginClass = " viewerbody-profile-container-padding";
    }

    let noComposer: boolean = this.props.noComment;

    let isDiscussion = !!(
      posting.boardsCategoriesAttribute &&
      posting.boardsCategoriesAttribute.includes(BoardAttribute.IS_DISCUSSION)
    );
    let canDislike = !!(
      posting.boardsCategoriesAttribute &&
      posting.boardsCategoriesAttribute.includes(BoardAttribute.CAN_DISLIKE)
    );
    let canAnonymous = !!(
      posting.boardsCategoriesAttribute &&
      posting.boardsCategoriesAttribute.includes(BoardAttribute.CAN_ANONYMOUS)
    );
    let emphasisLike = !!(
      posting.boardsCategoriesAttribute &&
      posting.boardsCategoriesAttribute.includes(
        BoardAttribute.UI_VIEWER_LIKE_DISLIKE_EMPHASIS
      )
    );

    if (this.content.deletedAt) {
      return (
        <>
          <div
            ref={(ref) => {
              this.focusRef = ref;
            }}
            className="viewerbody-container"
          >
            {flag}
            <div className="viewerbody-container-inner">
              <div className={"viewerbody-deleted" + profileMarginClass}>
                작성자가 삭제한 의견입니다.
              </div>
              {/* {toolbar} */}

              <ViewerComments
                open={this.props.open}
                noComposer={noComposer}
                comments={posting.comments}
                postingId={posting.id}
                postingGroupId={posting.groupId}
                onComment={this.onComment}
                onUserPressed={this.onUserPressed}
                onPartnerPressed={this.onPartnerPressed}
                onChange={this.onCommentChange}
                onDelete={this.onCommentDelete}
                onReport={this.onCommentReport}
                onReply={this.onCommentReply}
                onLike={this.onCommentLike}
                onDislike={this.onCommentDislike}
                lastViewedAt={lastViewedAt}
                findFocus={this.props.findFocus}
                onFindFocusComponent={this.onFindFocusComponent}
                isDiscussion={isDiscussion}
                canDislike={canDislike}
                canAnonymous={canAnonymous}
                anonymousNames={this.anonymousName}
                emphasisLike={emphasisLike}
                rootAuthorId={this.props.rootAuthorId}
                rootAuthorName={this.props.rootAuthorName}
              />
            </div>
          </div>
        </>
      );
    }

    noComposer = this.props.noComment;
    if (
      (!posting.groupParent &&
        !(
          posting.boardsCategoriesAttribute &&
          posting.boardsCategoriesAttribute.includes(
            BoardAttribute.CAN_COMMENT_ON_POST
          )
        )) ||
      (posting.groupParent &&
        !(
          posting.boardsCategoriesAttribute &&
          posting.boardsCategoriesAttribute.includes(
            BoardAttribute.CAN_COMMENT_ON_REPLY
          )
        ))
    ) {
      noComposer = true;
    }

    if (!posting.groupParent) {
      // Post toolbar
      if (
        this.content.boardsCategoriesAttribute.includes(
          BoardAttribute.CAN_SCRAP
        )
      ) {
        if (!this.props.noScrap) {
          // popoverMenu.push(this.menuScrap);
        }
      }
      if (
        this.content.boardsCategoriesAttribute.includes(
          BoardAttribute.CAN_SHARE
        )
      ) {
        if (!this.props.noKakaoShare) {
          // popoverMenu.push(this.menuKakaoShare);
        }
      }
    }

    // popoverMenu.push(this.menuCancel);

    let viewCnt = "";
    if (
      !this.props.noViewCnt &&
      !posting.groupParent &&
      posting.boardsCategoriesAttribute.includes(
        BoardAttribute.UI_VIEWER_PROFILE_ON_POST
      )
    ) {
      viewCnt = " · 조회수 " + posting.viewCnt;
    }

    let profile;
    if (!this.props.noProfile) {
      let withProfile: boolean = false;
      if (
        (!posting.groupParent &&
          posting.boardsCategoriesAttribute &&
          posting.boardsCategoriesAttribute.includes(
            BoardAttribute.UI_VIEWER_PROFILE_ON_POST
          )) ||
        (posting.groupParent &&
          posting.boardsCategoriesAttribute &&
          posting.boardsCategoriesAttribute.includes(
            BoardAttribute.UI_VIEWER_PROFILE_ON_REPLY
          ))
      ) {
        withProfile = true;
      }

      // let isRoot: boolean = !posting.groupParent;

      let customName = posting.usersCustomName;
      let usersProfileUrl = posting.usersProfileUrl;
      let name = posting.usersNickname;
      // if(this.props.rootAuthorId && this.props.rootAuthorId == posting.usersId){
      //   usersProfileUrl = "";
      //   customName = this.props.rootAuthorName;
      //   name = this.props.rootAuthorName;
      // }

      profile = (
        <ProfileSimple
          boardId={posting.id}
          isRoot={!posting.groupParent}
          anonymousName={this.anonymousName}
          onPress={this.onUserPressed}
          onPressPartner={this.onPartnerPressed}
          anonymous={posting.isAnonymous}
          withProfile={withProfile}
          isNice={posting.isNice}
          editable={posting.editable || posting.usersId === this.props.me.id}
          customName={customName}
          name={name}
          premiumType={posting.usersPremiumType}
          partner={this.props.partner}
          boardsCategoriesName={posting.boardsCategoriesName}
          userId={posting.usersId}
          profileUrl={usersProfileUrl}
          label={`${getDateTimeString(posting.createdAt)}`}
          blocked={posting.blocked}
          onClickMore={
            !posting.groupParent
              ? null
              : () => {
                  // this.setState({ showMenuPopover: true });
                  // this.popupBackkey("showMenuPopover");
                  let popoverMenu = [];

                  if (posting.editable && !this.props.noNavigate) {
                    popoverMenu.push(this.menuModify);
                    if (!posting.replies || posting.replies.length == 0)
                      popoverMenu.push(this.menuDelete);
                  } else {
                    if (isAdmin && !this.props.noNavigate) {
                      popoverMenu.push(this.menuAdminModify);
                      popoverMenu.push(this.menuAdminDelete);

                      if (!this.content.groupParent) {
                        if (this.content.noNice)
                          popoverMenu.push(this.menuAdminEnableNice);
                        else {
                          popoverMenu.push(this.menuAdminDisableNice);
                        }
                      }
                    }
                    popoverMenu.push(this.menuReport);
                  }

                  if (
                    !posting.groupParent &&
                    isAdmin &&
                    !this.props.noNavigate
                  ) {
                    // popoverMenu.push(this.menuAdminKeyword);
                    popoverMenu.push(this.menuAdminMove);
                  }

                  log(
                    LogLevel.UI_ACTION,
                    "ViewerBody.render: profile more click",
                    popoverMenu
                  );

                  this.props.bottomSheet.show({
                    body: (
                      <Flex direction="column">
                        {popoverMenu.map((menu, index) => (
                          <Flex
                            key={index}
                            alignItems="center"
                            justifyContent="space-between"
                            customStyle={{ height: "48px" }}
                            onClick={menu.handler}
                          >
                            {menu.textIcon ? (
                              <Flex alignItems="center" gap="12px">
                                {menu.textIcon.position === "left" && (
                                  <Icon
                                    name={menu.textIcon.name}
                                    width={menu.textIcon.width}
                                    height={menu.textIcon.height}
                                    color={menu.textIcon.color}
                                  />
                                )}
                                <Text
                                  textType="Body1"
                                  color={COLOR_SYSTEM.get("Red")[400]}
                                >
                                  {menu.text}
                                </Text>
                                {menu.textIcon.position === "right" && (
                                  <Icon
                                    name={menu.textIcon.name}
                                    width={menu.textIcon.width}
                                    height={menu.textIcon.height}
                                    color={menu.textIcon.color}
                                  />
                                )}
                              </Flex>
                            ) : (
                              <Text
                                textType="Body1"
                                color={COLOR_SYSTEM.get("Gray")[700]}
                              >
                                {menu.text}
                              </Text>
                            )}

                            {menu.isAdmin && (
                              <Text
                                textType="Body1"
                                color={COLOR_SYSTEM.get("Gray")[400]}
                              >
                                관리자
                              </Text>
                            )}
                            {menu.right}
                          </Flex>
                        ))}
                      </Flex>
                    ),
                  });
                }
          }
        />
      );
    }

    // let updatedFlag = (
    // <div className={"viewerbody-unread-flag-container" + profileMarginClass}>
    //   <div className="viewerbody-unread-flag">읽지 않은 글</div>
    // </div>
    // <Badge color="Red" content="N" />
    // );

    // if (isUpdatedFromLastView) {
    //   updatedFlag = (
    //     <div className={"viewerbody-unread-flag-container" + profileMarginClass}>
    //       <div className="viewerbody-unread-flag">읽지 않은 글</div>
    //     </div>
    //   );
    // }

    if (this.content.blocked) {
      // if (true) {
      return (
        // <>
        //   <div
        //     ref={(ref) => {
        //       this.focusRef = ref;
        //     }}
        //     className="viewerbody-container"
        //   >
        //     {flag}
        //     <div className="viewerbody-container-inner">
        //       {profile}
        //       <div className={"viewerbody-deleted"}>차단된 사용자의 글입니다.</div>
        //       <ViewerComments
        //         open={this.props.open}
        //         noComposer={noComposer}
        //         comments={posting.comments}
        //         postingId={posting.id}
        //         postingGroupId={posting.groupId}
        //         onComment={this.onComment}
        //         onUserPressed={this.onUserPressed}
        //         onChange={this.onCommentChange}
        //         onDelete={this.onCommentDelete}
        //         onReport={this.onCommentReport}
        //         onReply={this.onCommentReply}
        //         onLike={this.onCommentLike}
        //         onDislike={this.onCommentDislike}
        //         lastViewedAt={lastViewedAt}
        //         findFocus={this.props.findFocus}
        //         onFindFocusComponent={this.onFindFocusComponent}
        //         isDiscussion={isDiscussion}
        //         canDislike={canDislike}
        //         emphasisLike={emphasisLike}
        //         anonymousNames={this.anonymousName}
        //       />
        //     </div>
        //   </div>
        // </>
        <Static
          customStyle={{
            padding: "12px 20px 35px",
            backgroundColor: COLOR_SYSTEM.get("Gray")[0],
          }}
        >
          {profile}
          <Flex
            direction="column"
            gap="40px"
            alignItems="center"
            customStyle={{
              backgroundColor: COLOR_SYSTEM.get("Gray")[0],
              marginTop: "20px",
              padding: "60px 0",
              textAlign: "center",
            }}
          >
            <img src={blockedContent} width={120} height={120} />
            <Text textType="Body1Bold" color={COLOR_SYSTEM.get("Gray")[200]}>
              차단된 사용자의 글입니다.
            </Text>
          </Flex>
        </Static>
      );
    }

    if (this.props.isOnlyQuestion) {
      return (
        <div>
          {this.renderTitle()}
          <Static customStyle={{ marginTop: "16px" }}>
            <Text textType="Body1" color={COLOR_SYSTEM.get("Gray")[900]}>
              {ReactHtmlParser(html, { transform: this.transform })}
            </Text>
            {this.renderKeywords()}
            {this.renderImages()}
            {this.renderFiles()}
          </Static>
        </div>
      );
    }

    log(
      LogLevel.UI_LIFECYCLE,
      "ViewerBody:render",
      this.props.medicine,
      this.props,
      posting
    );
    return (
      <>
        <Static
          customStyle={{
            padding: "12px 20px 35px",
            backgroundColor: COLOR_SYSTEM.get("Gray")[0],
            transform: "translateZ(0)",
          }}
          selectable={getGlobal(GlobalKey.OS) == "browser"}
          ref={(ref) => {
            this.focusRef = ref;
          }}
          // className={"viewerbody-container" + (isUpdatedFromLastView ? " viewerbody-container-updated" : "")}
          // onClick={(e) => {
          //   e.stopPropagation();
          // }}
        >
          {/* {flag} */}
          {/* <div className="viewerbody-container-inner"> */}

          {/* {updatedFlag} */}
          {profile}
          {posting.cards && posting.cards.length && (
            <div className="common-container" style={{ marginTop: "12px" }}>
              <div className="viewerbody-cardview-container">
                <SwipeableViews
                  className="viewerbody-cardview-swipe"
                  enableMouseEvents
                  index={this.state.cardviewIndex}
                  onChangeIndex={this.handleChangeIndex}
                >
                  {posting.cards.map((item, index) => {
                    return (
                      <img
                        key={index.toString()}
                        className="viewerbody-cardview-image"
                        src={StringUtil.convertFilePath(item.url)}
                        onClick={(e) => {
                          var rect = e.currentTarget.getBoundingClientRect();
                          var x = (e.clientX - rect.left) / rect.width; //x position within the element.
                          var y = (e.clientY - rect.top) / rect.height; //y position within the element.
                          this.onClickCard(item, index, { x, y });
                        }}
                      />
                    );
                  })}
                </SwipeableViews>
                {this.state.cardviewIndex != 0 && (
                  <IonButton
                    color="common-button"
                    class="viewerbody-cardview-back-button"
                    style={{ left: "-15px", top: "calc(50% - 25px)" }}
                    onClick={() => {
                      this.handleChangeIndex(this.state.cardviewIndex - 1);
                    }}
                  >
                    <IonIcon
                      class="viewerbody-cardview-back-button-image"
                      name="arrow-back"
                      mode="ios"
                    />
                  </IonButton>
                )}
                {this.state.cardviewIndex < posting.cards.length - 1 && (
                  <IonButton
                    color="common-button"
                    class="viewerbody-cardview-back-button"
                    style={{ right: "-15px", top: "calc(50% - 25px)" }}
                    onClick={() => {
                      this.handleChangeIndex(this.state.cardviewIndex + 1);
                    }}
                  >
                    <IonIcon
                      class="viewerbody-cardview-back-button-image"
                      name="arrow-forward"
                      mode="ios"
                    />
                  </IonButton>
                )}
              </div>
              <div className="common-container-row">
                <div className="common-flex-grow" />
                <div className="viewerbody-cardview-page-count">
                  진행율 : {this.state.cardviewIndex + 1}/{posting.cards.length}
                </div>
              </div>
            </div>
            // </div>
          )}

          {posting.videoUrl && (
            <div className="common-container" style={{ marginTop: "10px" }}>
              <Video
                ref={(ref) => (this.videoEl = ref)}
                src={posting.videoUrl}
                autoStart={false}
                speed={1}
              />
            </div>
            // </div>
          )}
          {this.renderTitle()}

          <BodyContent>
            <Text textType="Body1" color={COLOR_SYSTEM.get("Gray")[900]}>
              {ReactHtmlParser(html, { transform: this.transform })}
            </Text>
          </BodyContent>

          {this.content.boardsCategoriesId == 15 && (
            <div
              className="viewerbody-cardview-container"
              style={{ marginTop: "12px" }}
              onClick={this.onClickBannerInPartner(this.content.id)}
            >
              <img src="/images/partners/partners_banner_in_content.svg" />
            </div>
          )}

          {this.renderKeywords()}
          {this.renderImages()}
          {this.renderFiles()}
          {/* <div className="viewerbody-tag-container"></div> */}
          {toolbar}
          {/* {ABTestUtil.isTest(ABTestFeature.UI_GPT_SUMMARY) &&
          this.bodySummary(this.content) ? (
            <SummaryContainer>
              <div>
                <Button
                  color="Primary"
                  size="Medium"
                  variant="Tinted"
                  type="IconWithText"
                  left
                  icon="Thumbs Up Filled"
                  onClick={this.onClickSummary}
                >
                  요약보기
                </Button>
                <br />
              </div>
              <Text textType="Body1" color={COLOR_SYSTEM.get("Gray")[900]}>
                질문 : {this.bodySummary(this.content).body}
                <br />
                {console.log(this.bodySummary(this.content))}
                {this.bodySummary(this.content).replies.map((reply)=>(
                  <Text textType="Body1" color={COLOR_SYSTEM.get("Gray")[900]}>
                    답변 : {reply.bodyText} <br/>
                  </Text>
                ))}
              </Text>
            </SummaryContainer>
          ) : (
            <></>
          )} */}

          <ViewerComments
            open={this.props.open}
            noComposer={noComposer}
            comments={posting.comments}
            postingId={posting.id}
            postingGroupId={posting.groupId}
            onComment={this.onComment}
            onUserPressed={this.onUserPressed}
            onPartnerPressed={this.onPartnerPressed}
            onChange={this.onCommentChange}
            onDelete={this.onCommentDelete}
            onReport={this.onCommentReport}
            onReply={this.onCommentReply}
            onLike={this.onCommentLike}
            onDislike={this.onCommentDislike}
            lastViewedAt={lastViewedAt}
            findFocus={this.props.findFocus}
            onFindFocusComponent={this.onFindFocusComponent}
            isDiscussion={isDiscussion}
            canDislike={canDislike}
            canAnonymous={canAnonymous}
            anonymousNames={this.anonymousName}
            emphasisLike={emphasisLike}
            rootAuthorId={this.props.rootAuthorId}
            rootAuthorName={this.props.rootAuthorName}
          />
        </Static>
        <IonActionSheet
          mode="ios"
          children
          header="정말로 삭제하시겠습니까?"
          isOpen={this.state.showDeletePopover}
          onDidDismiss={() => {
            this.setState(() => ({ showDeletePopover: false }));
            this.props.backKeyControl.popListener();
          }}
          buttons={[
            {
              text: "삭제하겠습니다.",
              cssClass: "common-menu-red",
              handler: () => {
                log(LogLevel.UI_ACTION, "Delete Menu Delete clicked");
                this.onDelete();
              },
            },
            {
              text: "취소",
              role: "cancel",
              cssClass: "common-menu-normal",
              handler: () => {
                log(LogLevel.UI_ACTION, "Delete Menu Cancel clicked");
              },
            },
          ]}
          backdropDismiss={true}
        />
        <IonActionSheet
          mode="ios"
          children
          header="이 글을 신고하시는 이유는 무엇인가요?"
          isOpen={this.state.showReportPopover}
          onDidDismiss={() => {
            this.setState(() => ({ showReportPopover: false }));
            this.props.backKeyControl.popListener();
          }}
          buttons={[
            {
              text: "글삭튀(답변이 달린 후 내용 삭제)",
              cssClass: "common-menu-normal",
              handler: () => {
                log(LogLevel.UI_ACTION, "Delete Menu Delete clicked");
                this.onReport(5);
              },
            },
            {
              text: "게시판 주제와 무관",
              cssClass: "common-menu-normal",
              handler: () => {
                log(LogLevel.UI_ACTION, "Delete Menu Delete clicked");
                this.onReport(4);
              },
            },
            {
              text: "폭언, 비속어, 혐오발언",
              cssClass: "common-menu-normal",
              handler: () => {
                log(LogLevel.UI_ACTION, "Delete Menu Delete clicked");
                this.onReport(1);
              },
            },
            {
              text: "홍보, 스팸, 광고",
              cssClass: "common-menu-normal",
              handler: () => {
                log(LogLevel.UI_ACTION, "Delete Menu Delete clicked");
                this.onReport(2);
              },
            },
            {
              text: "취소",
              role: "cancel",
              cssClass: "common-menu-normal",
              handler: () => {
                log(LogLevel.UI_ACTION, "Delete Menu Cancel clicked");
              },
            },
          ]}
          backdropDismiss={true}
        />
      </>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  medicine: state.medicine,
  boards: state.board.boards,
  contents: state.board.contents,
  filePath: state.board.filePath,
  links: state.board.links,
  me: state.user.me,
  imageViewerPopup: state.ui.popups[UIPopupType.IMAGE_VIEWER_POPUP],
  keywordPopup: state.ui.popups[UIPopupType.KEYWORD_POPUP],
  confirmPopup: state.ui.popups[UIPopupType.CONFIRM_POPUP],
  toast: state.ui.popups[UIPopupType.TOAST_POPUP],
  backKeyControl: state.ui.services[UIServiceType.BACK_CONTROLLER],
});

const mapDispatchToProps = {
  fetchKeyword: (type: KeywordType, id: number) =>
    actions.medicine.getMedicine(type, id),
  updateContent: (content: BoardContent) =>
    actions.board.updateContent(content),
  loadContent: (id: number) => actions.board.getContent(id),
  refreshBoards: (board: BoardType = BoardType.ALL) =>
    actions.board.refreshBoards(board),
  loadLink: (url: string, contentId: number) =>
    actions.board.loadLink(url, contentId),
  loadFilePath: () => actions.board.getFilePath(),
  checkAccomplished: () => actions.user.checkUserAccomplished(),
  setMe: (me) => actions.user.setMe(me),
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withBottomSheet(ViewerBody))
);
