import React, { Component } from "react";
import "./ConfirmPopup.css";
import "./../../Common.css";
import { withRouter, RouteComponentProps } from "react-router";
import {
  IonButton,
  IonModal,
  IonIcon,
  IonInput,
  IonList,
  IonItem,
} from "@ionic/react";
import { fetchAPI } from "./../../utils/APIUtil";
import * as API from "./../../API.json";
import { timeout } from "q";
import smileIcon from "./../../assets/icon/smile.svg";
import { log, LogLevel } from "../../utils/LogUtil";
import DynamicCheckerInput, { CheckStatus } from "./../DynamicCheckerInput";
import { getGlobal, GlobalKey } from "../../utils/GlobalUtil";
import Textarea from "react-textarea-autosize";
import { Flex } from "../atoms/Layout";
import Popup from "../molecules/Popup";
import Button from "../atoms/Button";
import Input from "../atoms/Input";
import Text from "../atoms/Text";
import { COLOR_SYSTEM } from "../design/design-system";

type Props = {};

type State = {
  visible: boolean;
  text: string;
};

interface ConfirmPopupOptions {
  maxHeight?: string;
  title?: any;
  body?: any;
  buttons?: any;
  onDone?: (testInput: string) => void;
  onCancel?: (testInput: string) => void;
  onBackdropDismiss?: () => void;
  backDropDismiss?: boolean;
  needInput?: boolean;
  needTextArea?: boolean;
  defaultText?: string;
  inputPlaceHolder?: string;
  isPassword?: boolean;
  iconImage?: any;
  iconName?: string;
  iconStyle?: string;
  image?: any;
  imageStyle?: string;
  doneText?: string;
  cancelText?: string;
  cancelButtonColorStyle?: string;
  confirmButtonColorStyle?: string;
  textAreaStyle?: string;
  needCloseButton?: boolean;
}

class ConfirmPopup extends Component<Props, State> {
  inputRef = null;

  state = {
    visible: false,
    text: "",
    emailCheckStatus: CheckStatus.CHECKED,
    emailWarning: "",
  };

  constructor(props) {
    super(props);
    this.inputRef = React.createRef();
  }

  initialOptions: ConfirmPopupOptions = {
    // title:(
    //   <div className="common-container">
    //     <span className="confirm-title-highlight">진행</span> 확인
    //   </div>
    // ),
    // body: (
    //   <div className="common-container">
    //     이 작업을 <span className="confirm-description-highlight">진행</span>하시겠습니까?
    //   </div>
    // ),
    onDone: null,
    onCancel: null,
    backDropDismiss: true,
    needInput: false,
    inputPlaceHolder: "",
    defaultText: "",
    isPassword: false,
    iconImage: null,
    iconName: null,
    doneText: "예",
    cancelText: null,
    cancelButtonColorStyle: "confirm-cancel-button",
  };

  options: ConfirmPopupOptions = { ...this.initialOptions };

  show = (options: ConfirmPopupOptions = {}) => {
    this.options = { ...this.initialOptions, ...options };
    let text = "";
    if (this.options.defaultText) text = this.options.defaultText;
    this.setState({ visible: true, text });
    if (this.options.needInput) {
      this.autoFocusInput();
    }
    log(LogLevel.UI_LIFECYCLE, "ConfirmPopup:show", options, this.options);
  };

  autoFocusTryMax = 10;
  autoFocusTry = 0;
  autoFocusInput = () => {
    setTimeout(() => {
      try {
        if (this.inputRef.current) {
          this.inputRef.current.setFocus();
          return;
        }
      } catch (e) {
        log(LogLevel.UI_EXCEPTION, "ConfirmPopup:autoFocusInput", e);
      }

      this.autoFocusTry++;
      if (this.autoFocusTry <= this.autoFocusTryMax) {
        log(
          LogLevel.UI_EXCEPTION,
          "ConfirmPopup:autoFocusInput try again",
          this.autoFocusTry
        );
        this.autoFocusInput();
      } else {
        log(
          LogLevel.UI_EXCEPTION,
          "ConfirmPopup:autoFocusInput failed fianally"
        );
      }
    }, 100);
  };

  hide = () => {
    log(LogLevel.UI_LIFECYCLE, "ConfirmPopup:hide", this.state);
    this.setState({ visible: false });
  };

  isShown = () => {
    return this.state.visible;
  };

  onDone = () => {
    this.hide();
    if (this.options.onDone) this.options.onDone(this.state.text);
    this.setState({ text: "" });
  };

  onCancel = () => {
    this.hide();
    if (this.options.onCancel) this.options.onCancel(this.state.text);
  };

  onClickBackdrop = () => {
    if (this.options.backDropDismiss) {
      this.hide();
      if (this.options.onBackdropDismiss) this.options.onBackdropDismiss();
    }
  };

  render() {
    let icon;
    if (this.options.iconImage)
      icon = <img className="confirm-icon" src={this.options.iconImage} />;
    else if (this.options.iconName)
      icon = <IonIcon name={this.options.iconName} />;
    else if (this.options.image)
      icon = (
        <img
          className={
            this.options.imageStyle ? this.options.imageStyle : "confirm-image"
          }
          src={this.options.image}
        />
      );
    let cancelButton;

    let buttons;
    if (this.options.buttons) {
      buttons = this.options.buttons;
    } else {
      if (this.options.cancelText) {
        if (typeof this.options.cancelText === "string") {
          cancelButton = (
            <Button
              onClick={this.onCancel}
              size={"Large"}
              type={"Text"}
              variant={"Contained"}
              color={"Tertiary"}
              style={{ flex: 1 }}
            >
              {this.options.cancelText}
            </Button>
          );
        } else {
          cancelButton = this.options.cancelText;
        }
      }

      buttons = (
        <Flex
          gap="8px"
          customStyle={{
            width: "100%",
          }}
        >
          {cancelButton}
          {typeof this.options.doneText === "string" ? (
            <Button
              onClick={this.onDone}
              size={"Large"}
              type={"Text"}
              variant={"Contained"}
              color={"Primary"}
              style={{ flex: 1 }}
            >
              {this.options.doneText}
            </Button>
          ) : (
            this.options.doneText
          )}
        </Flex>
      );
    }

    let input;
    if (this.options.needInput) {
      let placeholder = "";
      if (this.options.inputPlaceHolder) {
        placeholder = this.options.inputPlaceHolder;
      }
      input = (
        <Input
          inputRef={this.inputRef}
          placeholder={placeholder}
          value={this.state.text}
          type={this.options.isPassword ? "password" : "text"}
          onChange={(e) => {
            this.setState({ text: e.target.value });
          }}
          onEnter={this.onDone}
        />
      );
    } else if (this.options.needTextArea) {
      input = (
        <Input
          multiLine={true}
          value={this.state.text}
          onChange={(e) => {
            this.setState({ text: e.target.value });
          }}
          onClick={(e) => {
            e.stopPropagation();
          }}
        />
      );
    }

    let closeButton;
    if (this.options.needCloseButton) {
      closeButton = (
        <Button
          icon="X"
          color="Quaternary"
          size="Medium"
          variant="Ghost"
          style={{ position: "absolute", top: "10px", right: "10px" }}
          onClick={this.hide}
          type={"Icon"}
        />
      );
    }

    let title;
    if (this.options.title)
      title = (
        <Text textType="H4" color={COLOR_SYSTEM.get("Gray")[800]}>
          {this.options.title}
        </Text>
      );

    let body;
    if (this.options.body)
      body = (
        <Text textType="Body2" color={COLOR_SYSTEM.get("Gray")[600]}>
          {this.options.body}
        </Text>
      );
    if (!this.state.visible) return <div />;

    return (
      <Popup
        onClose={() => this.setState({ visible: false })}
        closeOnBackDrop={this.options.backDropDismiss}
        height="auto"
        maxHeight={this.options.maxHeight}
      >
        {icon}
        {title}
        {body}
        {input}
        {buttons}
        {closeButton}
      </Popup>
    );
  }
}

export default ConfirmPopup;
