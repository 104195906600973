import React, { Component } from "react";
import { connect } from "react-redux";
import { RootState, actions } from "../store";
import { withRouter, RouteComponentProps } from "react-router";
import {
  IonFooter,
  IonInput,
  IonBackButton,
  IonTitle,
  IonLoading,
  IonToast,
  IonIcon,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonMenuButton,
  IonSegment,
  IonSegmentButton,
  IonButton,
  IonSearchbar,
  IonContent,
  IonRefresher,
  IonRefresherContent,
  IonFab,
  IonFabList,
  IonFabButton,
  IonAlert,
  IonBadge,
  IonLabel,
  IonTextarea,
  IonCheckbox,
  IonToggle,
} from "@ionic/react";
import "./HtmlComposer.css";
import "./../Common.css";
import Profile from "../components/ProfileSimple";
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";
import MagicUrl from "quill-magic-url";
import Mention from "quill-mention";
import { lastIndexOfRegex } from "index-of-regex";
import AttachmentViewer from "../components/Attachment";
import qustionIcon from "./../assets/image/question.png";
import {
  BoardContent,
  Attachment,
  BoardAttribute,
} from "../models/Model.Board";
import { log, LogLevel } from "../utils/LogUtil";
import {
  getGlobal,
  GlobalKey,
  clearGlobal,
  setGlobal,
} from "../utils/GlobalUtil";
import BoardUtil from "../utils/BoardUtil";
import { isIOS } from "react-device-detect";
import { UIPopupType } from "../store/ui/types";
import { timerStart, timerStop } from "../utils/TimeUtil";
import { loadImageBase64 } from "../utils/ImageUtil";
import { timingSafeEqual } from "crypto";
import { fetchAPI } from "../utils/APIUtil";
import * as API from "./../API.json";
import { BoardType } from "../store/board/types";
import { UserLevel } from "../models/Model.User";
import { action } from "typesafe-actions";
import ViewerBodyPopup from "../components/ViewerBodyPopup";
import ComponentComposePopup from "../ivcomponent/ComponentComposePopup";
import AnalyticsUtil from "./../utils/AnalyticsUtil";
import Textarea from "react-textarea-autosize";
import ABTestUtil, { ABTestFeature } from "../utils/ABTestUtil";
import { SeminarLecturePartialLoadOption } from "../models/Model.Seminar";
import ComposeCardnewsPopup from "./popup/ComposeCardnewsPopup";

var Block = Quill.import("blots/block");
Block.tagName = "div";
Quill.register(Block);

var Inline = Quill.import("blots/inline");

var Embed = Quill.import("blots/embed");

class Keywords extends Embed {
  static create(value) {
    let node = super.create(value);
    log(LogLevel.UI_DATA_LOAD, "Keywords:value: ", value);
    node.setAttribute("keywordtype", value.type);
    node.setAttribute("keywordid", value.id);
    const keywords = document.createElement("span");
    keywords.className = "ql-mention-denotation-char";
    keywords.setAttribute("contenteditable", "false");
    keywords.textContent = value.text;

    node.appendChild(keywords);
    // node.textContent = '';
    // node.innerHTML += value.html;
    return Keywords.setDataValues(node, value);
  }

  static setDataValues(element, data) {
    const domNode = element;
    Object.keys(data).forEach((key) => {
      domNode.dataset[key] = data[key];
    });
    return domNode;
  }

  static value(domNode) {
    return domNode.dataset;
  }

  static formats(domNode) {
    return {
      type: domNode.getAttribute("keywordtype"),
      id: domNode.getAttribute("keywordid"),
    };
  }
}

Keywords.blotName = "keywords";
Keywords.className = "keywords";
Keywords.tagName = "SPAN";

class Components extends Embed {
  static blotName = "components";
  static className = "components";
  static tagName = "SPAN";

  static create(value) {
    let node = super.create(value);
    log(LogLevel.UI_DATA_LOAD, "Components:create: ", node, value);
    // for(let val in value){
    //   node.setAttribute(val, value[val]);
    // }

    const keywords = document.createElement("span");
    keywords.className = "html-composer-component-component";
    keywords.setAttribute("contenteditable", "false");
    keywords.textContent = "Component:" + value.component;

    node.appendChild(keywords);
    // node.textContent = '';
    // node.innerHTML += value.html;
    return Components.setDataValues(node, value);
    // return node;
  }

  static setDataValues(element, data) {
    const domNode = element;
    Object.keys(data).forEach((key) => {
      domNode.dataset[key] = data[key];
    });
    log(LogLevel.UI_DATA_LOAD, "Components:setDataValues: ", domNode, data);
    return domNode;
  }

  static value(domNode) {
    log(LogLevel.UI_DATA_LOAD, "Components:value: ", domNode);
    return domNode.dataset;
  }

  static formats(domNode) {
    let rvalue: any = {};
    // let atts = domNode.attributes;
    // for (var i = 0; i < atts.length; i++){
    //   rvalue[atts[i].nodeName] = atts[i].nodeValue;
    // }
    log(LogLevel.UI_DATA_LOAD, "Components:formats: ", rvalue);
    return rvalue;
  }
}

Quill.register(Keywords);
Quill.register(Components);
Quill.register("modules/magicUrl", MagicUrl);
Quill.register(Mention);

const keywordTypeName = ["", "성분", "의약품", "용어", "부작용", "동물의약품"];

type Props = RouteComponentProps &
  typeof mapDispatchToProps &
  ReturnType<typeof mapStateToProps> & {
    allowTitleNewLine?: boolean;
    titlePlaceholder?: string;
    title?: string;
    onTitleChanged?: (title: string) => void;

    bodyPlaceholder?: any;
    body?: string;
    onBodyChanged?: (body: string) => void;

    files?: Attachment[];
    filesLocal?: any[];
    onAddFile?: (file) => void;
    onDeleteFile?: (index) => void;
    onDeleteFileLocal?: (index) => void;

    showParentText?: string;
    onShowParent?: () => void;

    showProfile?: boolean;
    anonymous?: boolean;
    onAnonymousChanged?: () => void;
    isQnA?: boolean;

    cardnews?: Attachment[];
    onCardnewsChanged?: (cardnews: Attachment[]) => void;
    mini?: boolean;
    noFocus?: boolean;
  };

type State = {};

const queryString = require("query-string");
const globalAny: any = global;
const $: any = globalAny.$;

const windowAny: any = window;

class HtmlComposer extends Component<Props, State> {
  mounted = false;
  titleInput = null;
  reactQuillRef: any = null;
  ivComponentComposerPopup: any = null;
  ivCardnewsComposerPopup: any = null;
  currentIndex: number = 0;
  keywordSearch: string = "";

  contentLoadWait: boolean = false;

  showParentPopup = null;
  doneProcessing = false;

  static modules = {
    magicUrl: true,
    toolbar: {
      container: "#toolbar",
    },
    mention: {
      allowedChars: /\S/,
      mentionDenotationChars: ["@"],
      source: (searchTerm, renderList, mentionChar) => {
        if (searchTerm.length == 0) {
          renderList([], searchTerm);
          return;
        }
        fetchAPI(
          API.MEDICINE_DICTIONARY,
          "",
          { keyword: searchTerm, type: 0 },
          null,
          getGlobal(GlobalKey.TOKEN)
        ).then((result) => {
          log(LogLevel.UI_DATA_LOAD, "HtmlComposer.mention.source", searchTerm);

          if (result && !result.error) {
            let data = result.data.slice(0, 5);
            renderList(data, searchTerm);
          } else {
            renderList([], searchTerm);
          }
        });
      },
      renderItem: (item, searchTerm) => {
        let description = "";
        if (item.description)
          description = `<div class="html-composer-component-search-medicine-item-description">${item.description}</div>`;
        return `<div class="html-composer-component-search-medicine-item-container">
            <div class="html-composer-component-search-medicine-item-type"> ${
              keywordTypeName[item.type]
            }</div>
            <div class="html-composer-component-search-medicine-item-info"> 
              <div class="html-composer-component-search-medicine-item-name">${
                item.text
              }</div> 
              ${description}
            </div>
          </div>`;
      },
      onSelect: function (item, insertItem) {
        let newItem = { ...item };
        // newItem.value = `<span contentEditable='false'>${item.value}</span>`
        insertItem(newItem);
        AnalyticsUtil.event(
          AnalyticsUtil.TYPE_ALL,
          "PC_COMPOSER_SELECT_KEYWORD",
          "PC용_게시물_작성_키워드선택",
          { type: newItem.type, id: newItem.id, name: newItem.value }
        );

        log(
          LogLevel.UI_DATA_LOAD,
          "HtmlComposer.mention.onSelect",
          item,
          newItem
        );
      },
      showDenotationChar: false,
      dataAttributes: ["id", "value", "type"],
    },
  };

  static formats = [
    "keywords",
    "mention",
    "components",
    "bold",
    "underline",
    "blockquote",
    "link",
    "image",
    "color",
    "background",
  ];

  constructor(props: Props) {
    super(props);
    log(LogLevel.UI_LIFECYCLE, "HtmlComposer:constructor", this.props);
    timerStart();
  }

  async componentDidMount() {
    log(LogLevel.UI_LIFECYCLE, "HtmlComposer:componentDidMount");
    // 안드로이드 뒤로가기 버튼 탭 시
    this.mounted = true;
    if (this.titleInput && !this.props.noFocus)
      setTimeout(() => {
        this.titleInput.focus();
      }, 150);
    else if (this.reactQuillRef && !this.props.noFocus) {
      setTimeout(() => {
        // this.bodyInput.click();
        this.reactQuillRef.focus();
      }, 150);
    }

    // this.startAutoSave();
  }

  componentDidUpdate() {}

  focus = () => {
    log(LogLevel.UI_ACTION, "HtmlComposer:focus touched empty place");
    if (this.reactQuillRef && !this.props.noFocus) {
      // setTimeout(() => {
      // this.bodyInput.click();
      this.reactQuillRef.focus();
      // },150);
    }
  };

  handleChange = (value, delta, source, editor) => {
    log(LogLevel.UI_ACTION, "HtmlComposer:handleChange");
    if (this.props.onBodyChanged) this.props.onBodyChanged(value);
  };

  handleChangeSelection = (range, source, editor) => {
    let str = "";
    if (range) {
      this.currentIndex = range.index;
    }
    // str = Quill.getText();
    // log(LogLevel.UI_ACTION, "handleChangeSelection", range, source, str);
  };

  handleBlur = (previousRange, source, editor) => {
    // log(LogLevel.UI_ACTION, "handleBlur", previousRange, source, editor);
  };

  handleFocus = (range, source, editor) => {
    // log(LogLevel.UI_ACTION, "handleFocus", range, source, editor);
  };

  componentWillUpdate(props, states) {}

  componentWillUnmount() {
    if (this.props.progressPopup) this.props.progressPopup.hide();
    // document.removeEventListener("backbutton", this.goBack);
    this.mounted = false;
    // this.stopAutoSave();
  }

  confirmGoBack = () => {
    log(LogLevel.UI_ACTION, "HtmlComposer:goBack()", this.props);
    AnalyticsUtil.event(
      AnalyticsUtil.TYPE_AMPLITUDE,
      "PC_COMPOSER_EDIT_CANCEL",
      "PC용_게시물_작성중취소",
      { 소요시간: timerStop() }
    );
    AnalyticsUtil.event(
      AnalyticsUtil.TYPE_ALL,
      "COMPOSER_EDIT_CANCEL",
      "게시물_작성중취소",
      { 소요시간: timerStop() }
    );
    if (this.props.history.length > 1) this.props.history.goBack();
    else this.props.history.replace("/");
  };

  onCustomNameChange = (userCustomName: string) => {
    // this.setState({userCustomName});
  };

  onTitleChange = (e) => {
    log(LogLevel.UI_ACTION, "HtmlComposer:onTitleChange", e);
    let title = e.target.value;
    if (this.props.onTitleChanged) this.props.onTitleChanged(title);
  };

  onImageSelected = async (e) => {
    // console.dir(e);
    log(LogLevel.UI_ACTION, "HtmlComposer:onImageSelected", e.target.files);
    this.props.progressPopup.show();
    this.props.progressPopup.setLabel("이미지 로딩 중...");

    if (e.target.files && e.target.files.length > 0) {
      let targets = [...e.target.files];
      e.target.value = null;
      for (let i = 0; i < targets.length; i++) {
        let base64 = await loadImageBase64(targets[i]);
        if (base64) this.onImageLoad(base64);
      }
    }
    this.props.progressPopup.hide();
  };

  onImageLoad = (base64) => {
    log(LogLevel.UI_ACTION, "HtmlComposer:onImageLoad", base64.length);
    this.reactQuillRef
      .getEditor()
      .insertEmbed(this.currentIndex, "image", base64);
    this.reactQuillRef.getEditor().setSelection(this.currentIndex + 1);
    // this.setState({imagesBase64:[...this.state.imagesBase64, base64]});
  };

  onFileSelected = async (e) => {
    log(LogLevel.UI_ACTION, "HtmlComposer:onImageSelected", e.target.files);
    if (e.target.files && e.target.files.length > 0) {
      this.props.progressPopup.show();
      this.props.progressPopup.setLabel("파일 로딩 중...");
      let targets = [...e.target.files];
      e.target.value = null;
      for (let i = 0; i < targets.length; i++) {
        if (targets[i].type.startsWith("image")) {
          let base64 = await loadImageBase64(targets[i]);
          if (base64) this.onImageLoad(base64);
        } else {
          if (this.props.onAddFile) this.props.onAddFile(targets[i]);
        }
      }

      this.props.progressPopup.hide();
    }
  };

  onRemoveLocalFile = (i) => {
    log(LogLevel.UI_ACTION, "HtmlComposer:onRemoveFile", i);
    if (this.props.onDeleteFileLocal) this.props.onDeleteFileLocal(i);
  };

  onRemoveFile = (i) => {
    log(LogLevel.UI_ACTION, "HtmlComposer:onRemoveFile", i);
    if (this.props.onDeleteFile) this.props.onDeleteFile(i);
  };

  onEditorFocus = (e) => {
    log(LogLevel.UI_EVENT, "HtmlComposer:onEditorFocus", e);
    if (isIOS) {
      // $(".html-composer-component-footer-ios").show();
      // $(".html-composer-component-footer-container").addClass("html-composer-component-footer-container-onkeyboard");
      // $(".html-composer-component-footer-buttonbar-container").addClass("html-composer-component-footer-buttonbar-container-onkeyboard");
    }
  };

  onEditorBlur = (e) => {
    // console.log("blur", e)
    if (isIOS) {
      // $(".html-composer-component-footer-ios").hide();
      // $(".html-composer-component-footer-container").removeClass("html-composer-component-footer-container-onkeyboard");
      // $(".html-composer-component-footer-buttonbar-container").removeClass("html-composer-component-footer-buttonbar-container-onkeyboard");
    }
  };

  onCamera = () => {
    log(LogLevel.UI_ACTION, "HtmlComposer:onCamera");
    let os = getGlobal(GlobalKey.OS);
    if (!os || os == "browser") return;

    if (
      windowAny.navigator &&
      windowAny.navigator.camera &&
      windowAny.navigator.camera.getPicture
    ) {
      windowAny.navigator.camera.getPicture(
        this.onCameraSuccess,
        this.onCameraFail,
        {
          quality: 80,
          destinationType: windowAny.Camera.DestinationType.DATA_URL,
          encodingType: windowAny.Camera.EncodingType.JPEG,
          mediaType: windowAny.Camera.MediaType.PICTURE,
          correctOrientation: true,
          targetWidth: 1920,
          targetHeight: 1920,
        }
      );
    }
  };

  onCameraSuccess = (imageURI) => {
    // this.setState({imagesBase64:[...this.state.imagesBase64, "data:image/jpeg;base64," + imageURI]});
    this.onImageLoad("data:image/jpeg;base64," + imageURI);
  };

  onCameraFail = (message) => {
    log(
      LogLevel.UI_EXCEPTION,
      "HtmlComposer:onCameraFail Failed because: ",
      message
    );
  };

  onTitleKeyPress = (e) => {
    log(
      LogLevel.UI_ACTION,
      "ViewerCommentComposer:onKeyPress",
      e.keyCode,
      e.shiftKey
    );
    if (!this.props.allowTitleNewLine && e.keyCode == 13) {
      e.preventDefault();
    }
  };

  renderTitle() {
    if (this.props.onTitleChanged) {
      let question;
      if (this.props.isQnA)
        question = (
          <img
            className="html-composer-component-title-icon"
            src={qustionIcon}
          />
        );
      return (
        <div className="html-composer-component-title-container">
          {question}
          <div className="html-composer-component-title-input-container">
            <Textarea
              minRows={1}
              maxRows={this.props.allowTitleNewLine ? 30 : 4}
              inputRef={(ref) => {
                this.titleInput = ref;
              }}
              className="html-composer-component-title-input"
              value={this.props.title}
              placeholder={this.props.titlePlaceholder}
              onChange={this.onTitleChange}
              onFocus={this.onEditorFocus}
              onBlur={this.onEditorBlur}
              onClick={(e) => {
                e.stopPropagation();
              }}
              onKeyDown={this.onTitleKeyPress}
            />
          </div>
        </div>
      );
    }
    return null;
  }

  renderImageAddButton() {
    return (
      <div className="html-composer-component-image-add-container">
        <IonButton
          color="html-composer-component-image-add-button"
          fill="clear"
        >
          <IonIcon class="html-composer-component-image-add-icon" name="add" />
        </IonButton>
        <input
          className="html-composer-component-image-add-file"
          type="file"
          accept="image/*"
          multiple
          onChange={this.onImageSelected}
        />
      </div>
    );
  }

  renderCameraButton() {
    let camera;
    let os = getGlobal(GlobalKey.OS);
    if (os && os != "browser") {
      camera = (
        <IonButton
          color="html-composer-component-footer-button"
          fill="clear"
          onClick={this.onCamera}
        >
          <IonIcon
            class="html-composer-component-footer-button-icon"
            name="camera"
          />
        </IonButton>
      );
    }
    return camera;
  }

  showParent = () => {
    if (this.showParentPopup) this.showParentPopup.show();
  };

  onAddComponent = (data) => {
    log(LogLevel.UI_ACTION, "HtmlComposer:onAddComponent", data);
    // this.reactQuillRef.getEditor().insertEmbed(range.index, 'keywords', {text:text, id:item.id, type:item.type});

    this.reactQuillRef
      .getEditor()
      .insertEmbed(this.currentIndex, "components", data);
    this.reactQuillRef.getEditor().insertText(this.currentIndex + 1, " ");
    this.reactQuillRef.getEditor().setSelection(this.currentIndex + 2);
  };

  showComponentComposer = () => {
    let me = this.props.me;
    let isAdmin: boolean = me.level >= UserLevel.MANAGER;
    if (!isAdmin) return;
    if (this.ivComponentComposerPopup) this.ivComponentComposerPopup.show();
  };

  showCardnewsComposer = () => {
    if (!this.props.onCardnewsChanged) return;
    if (this.ivCardnewsComposerPopup) this.ivCardnewsComposerPopup.show();
  };

  render() {
    let me = this.props.me;
    let isAdmin: boolean = me.level >= UserLevel.MANAGER;

    let ivComponentButton;
    let ivComponentComposer;
    let ivCardnewsButton;
    let ivCardnewsComposer;

    if (isAdmin) {
      // if(false){
      ivComponentButton = (
        <div
          className="html-composer-toolbar-button-container common-color-caution common-bold"
          onClick={this.showComponentComposer}
        >
          Co
        </div>
      );
      ivComponentComposer = (
        <ComponentComposePopup
          ref={(ref) => {
            this.ivComponentComposerPopup = ref;
          }}
          onDone={this.onAddComponent}
        />
      );
    }

    if (this.props.onCardnewsChanged) {
      ivCardnewsButton = (
        <div
          className="html-composer-toolbar-button-container common-color-highlight common-bold"
          onClick={this.showCardnewsComposer}
        >
          Ca
        </div>
      );
      ivCardnewsComposer = (
        <ComposeCardnewsPopup
          ref={(ref) => {
            this.ivCardnewsComposerPopup = ref;
          }}
          progressPopup={this.props.progressPopup}
          cardnews={this.props.cardnews}
          onDone={this.props.onCardnewsChanged}
        />
      );
    }

    let toolbar;
    if (this.props.onBodyChanged) {
      toolbar = (
        <div id="toolbar">
          <button className="ql-bold" />
          <button className="ql-underline" />
          <button className="ql-blockquote" />
          <button className="ql-color" value="blue" />
          <button className="ql-background" value="yellow" />
          <div className="html-composer-component-toolbar-button-container">
            <IonIcon
              class="html-composer-component-toolbar-button-icon"
              name="image"
            />
            <input
              className="html-composer-component-toolbar-button-file"
              multiple={true}
              type="file"
              accept="image/*"
              onChange={this.onImageSelected}
            />
          </div>
          <div className="html-composer-component-toolbar-button-container">
            <IonIcon
              class="html-composer-component-toolbar-button-icon"
              name="attach"
            />
            <input
              className="html-composer-component-toolbar-button-file"
              multiple={true}
              type="file"
              onChange={this.onFileSelected}
            />
          </div>
          {ivComponentButton}
          {ivCardnewsButton}
        </div>
      );
    }

    let anonymous;
    if (this.props.onAnonymousChanged) {
      anonymous = (
        <div className="text-composer-component-anonymous-container">
          <IonToggle
            class="text-composer-component-anonymous-toggle"
            mode="ios"
            checked={this.props.anonymous}
            onClick={(e) => this.props.onAnonymousChanged()}
          />
          <div
            className={
              this.props.anonymous
                ? "text-composer-component-anonymous-button"
                : "text-composer-component-anonymous-button-disabled"
            }
            onClick={(e) => this.props.onAnonymousChanged()}
          >
            {" "}
            익명으로 작성{this.props.anonymous ? "중" : "하기"}{" "}
          </div>
        </div>
      );
    }

    let profile;
    if (this.props.showProfile) {
      profile = (
        <div className="text-composer-component-profile-container">
          <Profile
            name={this.props.me.nickname}
            withProfile={!this.props.anonymous}
            profileUrl={this.props.me.profileUrl}
            anonymous={this.props.anonymous}
          />
          {anonymous}
        </div>
      );
    }

    let showParentButton;
    if (this.props.onShowParent) {
      showParentButton = (
        <IonButton
          color="text-composer-component-show-parent"
          onClick={this.props.onShowParent}
        >
          <div className="text-composer-component-show-parent">
            {this.props.showParentText}
          </div>
        </IonButton>
      );
    }

    let hasBody =
      (this.props.body && this.props.body.length > 0) ||
      (this.props.files && this.props.files.length > 0) ||
      (this.props.filesLocal && this.props.filesLocal.length > 0);

    let bodyEditor;
    if (this.props.onBodyChanged) {
      bodyEditor = (
        <div className="html-composer-component-body-container">
          <ReactQuill
            style={{ width: "100%" }}
            ref={(el) => {
              this.reactQuillRef = el;
            }}
            theme={"snow"}
            onChange={this.handleChange}
            onBlur={this.handleBlur}
            onFocus={this.handleFocus}
            onChangeSelection={this.handleChangeSelection}
            value={this.props.body}
            modules={HtmlComposer.modules}
            formats={HtmlComposer.formats}
          />
          <span
            className={
              hasBody ? "composer-hidden" : "composer-content-placeholder"
            }
          >
            {this.props.bodyPlaceholder}
          </span>
          <div className="html-composer-component-files-container">
            {this.props.files &&
            this.props.files.length &&
            this.props.onDeleteFile
              ? this.props.files.map((file, i) => (
                  <AttachmentViewer
                    key={i.toString()}
                    name={file.name}
                    size={file.size}
                    onDelete={() => this.onRemoveFile(i)}
                  />
                ))
              : null}
            {this.props.filesLocal &&
            this.props.filesLocal.length &&
            this.props.onDeleteFileLocal
              ? this.props.filesLocal.map((file, i) => (
                  <AttachmentViewer
                    key={i.toString()}
                    name={file.name}
                    size={file.size}
                    onDelete={() => this.onRemoveLocalFile(i)}
                  />
                ))
              : null}
          </div>
        </div>
      );
    }

    return (
      <div
        className={
          this.props.mini ? "html-composer-component-mini" : "common-content"
        }
        onClick={this.focus}
      >
        <div className="html-composer-component-container">
          {this.props.cardnews && this.props.cardnews.length > 0 && (
            <AttachmentViewer
              name="Cardnews"
              onPress={this.showCardnewsComposer}
            />
          )}
          {profile}
          {this.renderTitle()}
          {toolbar}
          {bodyEditor}
        </div>
        {ivComponentComposer}
        {ivCardnewsComposer}
      </div>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  me: state.user.me,
  filePath: state.board.filePath,
  progressPopup: state.ui.popups[UIPopupType.WAITING_POPUP],
});

const mapDispatchToProps = {
  refreshBoards: (board: BoardType = BoardType.ALL) =>
    actions.board.refreshBoards(board),
  updateContent: (content: BoardContent) =>
    actions.board.updateContent(content),
  loadContent: (id: number) => actions.board.getContent(id),
  checkAccomplished: () => actions.user.checkUserAccomplished(),
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(HtmlComposer)
);
